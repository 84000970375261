import apiUser from "../../api/apiUser"

export const LoadUserDataAction = (date,jwt,context) => {
    return async (dispatch) => {
         apiUser.loadDataByDate(date,jwt).then(async ({response,error})=>{
             if(response){
                response.calories.bmi = Number(response.calories?.bmi??0).toFixed(2)
                response.calories.calories_consumed = Number(response.calories.calories_consumed??0).toFixed(2)
                response.calories.ideal_weight = Number(response.calories.ideal_weight??0).toFixed(2)
                response.calories.optimal_weight = Number(response.calories.optimal_weight??0).toFixed(2)
                // if(response.calories.total_cho==1)
                    // context.notify("برای بررسی BMI  لطفا سوالات اطلاعات جسمانی را پر کنید","info")
                await dispatch({ type: "USERDATA", payload: response })
             }
         })
       
        

    }
}