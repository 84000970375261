const set520Lower = () => {
  return {
    rateCircle: 15,
    nameOfDays: ["ش", "ی", "د", "س", "چ", "پ", "ج"],
  };
};
const set386Lower = () => {
  return {
    rateCircle: 15,
    nameOfDays: ["ش", "ی", "د", "س", "چ", "پ", "ج"],
  };
};
const setDefault = () => {
  return {
    rateCircle: 20,
    nameOfDays: [
      "شنبه",
      "یک شنبه",
      "دو شنبه",
      "سه شنبه",
      "چهار شنبه",
      "پنج شنبه",
      "جمعه",
    ],
  };
};
const responsive = ({ width, height }) => {
  //   const componentsSizeResponse = {};
  console.log(width, 3333333, width < 520);
   if (width < 386) return set386Lower();
  else if (width < 520) return set520Lower();

  else return setDefault();
};

export default responsive;
