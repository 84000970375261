import React, { useEffect, useRef, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { Avatar } from "rsuite";
import apiSearch from "../../../api/apiSearch";
import { Placeholder } from "rsuite";
import DialogListCategory from "../../dialogs/search/DialogListCategory";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";

const Categories = () => {
  const [cats, setCats] = useState([]);
  const jwt = useSelector((state) => state.jwt);
  const dialogListCategory = useRef();
  const width = window.innerWidth;
  useEffect(() => {
    apiSearch.loadCategoreis(jwt).then(({ response, error }) => {
      if (response) {
        setCats(response);
      }
    });
  }, []);
  return (
    <div className="d-flex flex-row align-items-center m-2">
      <DialogListCategory ref={dialogListCategory} />
      {cats.length == 0 ? (
        Array.from(Array(10).keys()).map((x, y) => {
          return <Placeholder.Paragraph key={y} graph="square" />;
        })
      ) : (
        <ScrollingCarousel className="gap-2">
          {cats.map((item, index) => {
            return (
              <div
                key={index}
                role="button"
                onClick={() => dialogListCategory?.current?.open(item)}
                className="cat-item "
              >
                <Avatar
                  src={item.cover}
                  style={{ width: width / 6, backgroundColor: "transparent" }}
                  className="p-2"
                />
                <p className="text-center">{item.name}</p>
              </div>
            );
          })}
        </ScrollingCarousel>
      )}
    </div>
  );
};

export default Categories;
