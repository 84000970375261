import React, { useContext, useEffect, useState ,useRef} from "react";
import SemiCircleProgressBar from "react-progressbar-semicircle";

import { useDispatch, useSelector } from "react-redux";
import { Progress, Rate } from "rsuite";
import UserContext from "../../../context/UserContext";
import apiFood from "../../../api/apiFood";
import { LoadUserDataAction } from "../../../redux/actions/userDataActions";

const BMI = (props) => {
  const userdata = useSelector((state) => state.userdata);
  const jwt=useSelector(state=>state.jwt)
  const context = useContext(UserContext);
  const [percent, setPercent] = useState(0);
  const dispatch=useDispatch()
  const infoRef = useRef();

  const loadFoodLogging = () => {
    let _date=props.selectedDate.locale("en").format("YYYY-MM-DD")
        dispatch(LoadUserDataAction(_date,jwt,context));
  };
  useEffect(()=>{
    if(jwt)
    loadFoodLogging();
  },[jwt])
  useEffect(() => {
    if (userdata) {
      let _percent =
        (Math.round(Number(userdata?.calories?.calories_consumed ?? 0)) * 100) /
        Math.round(Number(userdata?.calories?.total_calories ?? 100));

      _percent =
        isNaN(_percent) || userdata?.calories?.total_calories == 0
          ? 0
          : _percent;

      setPercent(_percent);
    }
  }, [userdata]);
  const checkTotal=(total)=>{
    if(total==1)
    return 0;
    return Number(total).toFixed(2)
  }
  return (
    <div role="button" onClick={props.onOpen} className="bmi border border-1 rounded-3 m-2" >
       
       <div className="info" ref={infoRef}>
      <div className="item position-relative">
        <img src="/images/icons/fat.svg" className="info-img" alt="" />
        <Progress.Circle
          percent={userdata?.calories.fat}
          strokeWidth={15}
          showInfo={false}
          trailColor={"rgba(255, 200, 7, 0.3)"}
          style={{width:100}}
          trailWidth={15}
          strokeColor="#ffc107"
       ></Progress.Circle> 
       <h6 className="pt-2">چربی</h6>
       <p dir="ltr"><span className="value" >{Number(userdata?.calories.fat??0).toFixed(2)}</span>/{checkTotal(userdata?.calories.total_fat??0)} g</p>
      </div>
      <div className="item position-relative">
        <img src="/images/icons/carbo.svg" className="info-img" alt="" />
        <Progress.Circle
          percent={userdata?.calories.carbohydrate}
          strokeWidth={15}
          style={{width:100}}
          showInfo={false}
          trailWidth={15}
          trailColor={"rgba(160, 229, 217, 0.3) "}
          key="a22"
          strokeColor="#A0E6D9"
        />
         <h6 className="pt-2">کربوهیدرات</h6>
         <p dir="ltr"><span className="value" >{Number(userdata?.calories.carbohydrate_g??0).toFixed(2)}</span>/{checkTotal(userdata?.calories.total_cho??0)} g</p>
      </div>
      <div className="item position-relative">
        <img src="/images/icons/pro.svg" className="info-img" alt="" />
        <Progress.Circle
          percent={userdata?.calories.protein}
          strokeWidth={15}
          showInfo={false}
          trailWidth={15}
          style={{width:100}}
          trailColor={"rgba(184, 169, 248,0.3)   "}
          strokeColor="#B8A9F8"
        />
         <h6 className="pt-2">پروتئین</h6>
        <p dir="ltr"><span className="value" >{Number(userdata?.calories.protein_g??0).toFixed(2)}</span>/{checkTotal(userdata?.calories.total_pro??0)} g</p>
      </div>
      </div>
      <div className="w-100 text-center position-relative">

      <SemiCircleProgressBar height={"100%"} diameter={Number(infoRef?.current?.offsetWidth-20)} percentage={percent}  showPercentValue={false} strokeWidth={20} />
      <div className=" calories-person  "  dir="ltr">
          <img src="/images/icons/fire.svg" width={30}  alt="" />
          <span>کالری</span>
          <span><span className="fw-bold">{Number(userdata?.calories.calories_consumed??0).toFixed(2)}</span>/{checkTotal(userdata?.calories.total_calories??0)} kcal</span>
      </div>
      </div>
      
    </div>
  );
};

export default BMI;
