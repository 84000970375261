import Global from "../config.json";
import httpService from "../services/httpService";
const apiUser = {
  async requestOTP(mobile, jwt) {
    let res = { response: null, error: null, server: null };
    let resStatus = 0;
    await httpService
      .post(
        `${Global.HealyMealy}user/phone/request-otp/`,
        JSON.stringify({
          phone_number: mobile,
        })
      )

      .then((json) => {
        console.log(json, resStatus);
        switch (json.status) {
          case 200:
          case 201:
            res.response = json.data;
            break;
          case 400:
          case 401:
          case 404:
            res.error = json;
            break;
        }
      })
      .catch((error) => {
        res.server = error;
      });

    return res;
  },
  async otpLogin(phoneNumber, number) {
    let res = { data: null, error: null };

    let resStatus = 0;

    await httpService
      .post(
        `${Global.HealyMealy}user/otp-login/`,
        JSON.stringify({
          phone_number: phoneNumber.toString(),
          otp: number.toString(),
        })
      )
      .then((response) => {
        console.log(response);
        switch (response.status) {
          case 200:
            res.data = response.data;
            break;
        }
      })
      .catch((error) => {
        console.log(error, 888);

        switch (error?.response?.status) {
          case 400:
          case 401:
          case 404:
            res.error = error.response;
            break;
        }
      });

    return res;
  },
  async loadUserProfile(jwt) {
    let res = null;
    console.log("loadUserProfile", jwt);
    await httpService
      .get(`${Global.HealyMealy}user/profile/`, {
        headers: jwt,
      })

      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        }
      })
      .catch((error) => {
        if(error?.response)
        switch (error.response.status) {
          case 400:
          case 401:
            res = null;
            break;
        }
      });
    return res;
  },
  async getWeightChart(jwt) {
    let res = { response: null, error: null };
    await httpService
      .post(`${Global.HealyMealy}user/bmi/`,{}, {
        headers: jwt,
      })

      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 200:
          case 201:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        if (error.response)
          switch (error.response.status) {
            case 400:
              res.error = error.response.data;
              break;
          }
      });
    return res;
  },
  async getUserData(jwt) {
    let res = { response: null, error: null };
    await httpService
      .post(`${Global.HealyMealy}user/bmi/`,{} ,{
        headers: jwt,
      })

      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 200:
          case 201:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        if (error.response)
          switch (error.response.status) {
            case 400:
            case 401:
              res.error = error.response.data;
              break;
          }
      });
    return res;
  },
  async getUserBodyData(jwt) {
    let res = { response: null, error: null };
    await httpService
      .get(`${Global.HealyMealy}user/physical-info/`,{
        headers: jwt,
      })

      .then((json) => {
        console.log(json,"body data");
        switch (json.status) {
          case 200:
          case 201:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        if (error.response)
          switch (error.response.status) {
            case 400:
              res.error = error.response.data;
              break;
          }
      });
    return res;
  },
  async setUserBody(data,jwt) {
    let res = { response: null, error: null };
    await httpService
      .post(`${Global.HealyMealy}user/physical-info/`,data,{
        headers: jwt,
      })

      .then((json) => {
        console.log(json,"send birthday data");
        switch (json.status) {
          case 200:
          case 201:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        if (error.response)
          switch (error.response.status) {
            case 400:
              res.error = error.response.data;
              break;
          }
      });
    return res;
  },
  async setUsername(nickname,jwt) {
    let res = { response: null, error: null };
    console.log(jwt)
    await httpService
      .post(`${Global.HealyMealy}user/profile/`,{nickname:nickname},{
        headers: jwt,
      })

      .then((json) => {
        console.log(json,"set username");
        switch (json.status) {
          case 200:
          case 201:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        console.log(error)
        if (error.response)
          switch (error.response.status) {
            case 400:
              res.error = error.response.data;
              break;
          }
      });
    return res;
  },
  async loadUserFavorite(jwt) {
    let res = null;
    await httpService
      .get(`${Global.HealyMealy}recipe/my-favorite/`, {
        headers: jwt,
      })

      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        }
      })
      .catch((error) => {
        if(error?.response)
        switch (error.response.status) {
          case 400:
          case 401:
            
            res = error.response.data;
            break;
        }
      });
    return res;
  },
  async logout(jwt) {
    let res = { response: null, error: null };
    console.log(jwt)
    await httpService
      .post(`${Global.HealyMealy}o/revoke_token/`,{
        headers: jwt,
      })

      .then((json) => {
        switch (json.status) {
          case 200:
          case 201:
          case 202:
          case 204:
            res.response = 200;
            break;
        }
      })
      .catch((error) => {
        console.log(error)
        if (error.response)
          switch (error.response.status) {
            case 400:
              res.error = error.response.data;
              break;
          }
      });
    return res;
  },
  async loadDataByDate(date, jwt) {
    let res = { response: null, error: null };

    let resStatus = 0;
    await httpService
      .get(`${Global.HealyMealy}home/?date=${date}`, {
        headers: jwt,
      })
      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 201:
          case 200:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 400:
          case 401:
          case 404:
            res.error =error.response.data;
            break;
        }
      });
     
    return res;
  },
  async getWater(date,jwt) {
    let res = { response: null, error: null };
    await httpService
      .get(`${Global.HealyMealy}home/water/drink/?date=${date}`, {
        headers: jwt,
      })

      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 200:
          case 201:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        if (error.response)
          switch (error.response.status) {
            case 400:
              res.error = error.response.data;
              break;
          }
      });
    return res;
  },
  async setWater(number,date,jwt) {
    let res = { response: null, error: null };
    await httpService
      .post(`${Global.HealyMealy}home/water/drink/`,{total:number,date:date}, {
        headers: jwt,
      })

      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 200:
          case 201:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        if (error.response)
          switch (error.response.status) {
            case 400:
              res.error = error.response.data;
              break;
          }
      });
    return res;
  },
  async addWater(date,jwt) {
    let res = { response: null, error: null };
    await httpService
      .post(`${Global.HealyMealy}home/water/drink/`,{date:date}, {
        headers: jwt,
      })

      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 200:
          case 201:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        if (error.response)
          switch (error.response.status) {
            case 400:
              res.error = error.response.data;
              break;
          }
      });
    return res;
  },
};
export default apiUser;
