import React, { useRef, useState } from "react";
import { MdSearch } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Input, InputGroup, Loader,Button } from "rsuite";
import apiFood from "../../../api/apiFood";
import ShowAll from "../../common/ShowAll";

const SearchBox = () => {
  const jwt = useSelector((state) => state.jwt);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [searchTimer, setSearchTimer] = useState();
  const [recList, setRecList] = useState([]);
  const showAll=useRef()
  const [recAllList, setRecAllList] = useState([]);
  const [matList, setMatList] = useState([]);
  const [recMatAllList, setMatAllList] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const requestSearchFood = async (query) => {
    // context.isLoading(true);
    await apiFood.searchFood(query, jwt).then(({ response, error, server }) => {
      setIsLoading(false);
      console.log(response, error, server, 7777777);
      setRecList(response.recipe.slice(0, 6));
      setRecAllList(response.recipe);
      setMatAllList(response.material);
      setMatList(response.material.slice(0, 6));
    });
    // .finally(() => context.isLoading(false));
  };
  const handleSearch = (query) => {
    console.log(query);
    setIsLoading(true);
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    setSearchTimer(
      setTimeout(() => {
        if (query != "") requestSearchFood(query);
        else {
          setRecList([]);
          setMatList([]);
          setIsLoading(false);
        }
      }, 1000)
    );
  };
  const showUnits = (item, type) => {
    let _data = { ...data, item: item, type: type };
    setRecList([]);
    setMatList([]);
    console.log(_data);
    // dialogAmount.current.open(_data);
  };
  const showRecMore = () => {
    console.log(recAllList);
    setRecList(recAllList.slice(0, recList.length + 6));
  };
  return (
    <div className="search-box position-relative">
      <ShowAll  ref={showAll}/>
      <InputGroup inside>
        <Input
          placeholder={" هر رسپی رو که می‌خوای رو جستجو کن!"}
          onChange={(value) => {
            handleSearch(value);
          }}
        />

        <InputGroup.Button>
          {isLoading ? (
            <span className=" loader">
              <Loader />
            </span>
          ) : (
            <MdSearch size={24} />
          )}
        </InputGroup.Button>
      </InputGroup>
      {recList.length > 0 && (
        <div
          className="card border-1  p-3 m-1 position-absolute w-100 bg-white"
          style={{ zIndex: 99 }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <p className="fw-bold">دستور پخت ها</p>
            <Button appearance="ghost" className="mb-1" onClick={()=>showAll.current.open(recAllList)} >
              نمایش همه
            </Button>
          </div>

          <ul className="list-group list-group-flush ">
            {recList.map((item, index) => {
              return (
                <li
                  role="button"
                  key={index}
                  onClick={() => showUnits(item, "recipe")}
                  className="list-group-item direction"
                >
                  <Link to={`/food/${item.id}`}>{item.name}</Link>
                </li>
              );
            })}
            {recList.length < recAllList.length && (
              <li
                role="button"
                onClick={showRecMore}
                className="list-group-item fw-bold"
              >
                نمایش بیشتر
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchBox;
