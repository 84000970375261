import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Modal, Pagination } from "rsuite";

import apiFoods from "../../../api/apiFoods";
import Item from "../../common/Item";

const DialogListCategory = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [cat, setCat] = useState(null);
  const [activePage, setActivePage] = React.useState(1);
  const handleClose = () => setOpen(false);
  const loadData = async (cat, page) => {
    let _items = await apiFoods.loadCategoryFoods(cat.id, page);
    console.log(_items);
    setData(_items);
  };
  useEffect(() => {
    if (cat !== null) loadData(cat, activePage);
  }, [activePage]);

  useImperativeHandle(ref, () => ({
    open(cat) {
      setOpen(true);
      setCat(cat);
      loadData(cat, 1);
    },
  }));
  return (
    <div>
      <Modal open={open} keyboard={true} backdrop={true} onClose={handleClose}>
        <Modal.Header className="text-center">{cat?.name}</Modal.Header>
        <Modal.Body>
          {data.results?.map((item, index) => {
            return <Item key={index} index={index} item={item} />;
          })}
          <div className="d-flex justfy-content-center align-items-center w-100">
            <Pagination
              className={"d-block text-center mx-auto"}
              total={data?.all_page}
              limit={1}
              activePage={activePage}
              onChangePage={setActivePage}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
});

export default DialogListCategory;
