import React, { useContext, useEffect, useRef, useState } from "react";
import { MdArrowBack, MdLogout, MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { IconButton } from "rsuite";
import apiUser from "../../api/apiUser";
import UserContext from "../../context/UserContext";
import { removeJWTAction } from "../../redux/actions/jwtActions";
import { loadUserProfileAction } from "../../redux/actions/userActions";
import Chart from "../common/Chart";
import DialogFavorite from "../dialogs/favorite/DialogFavorite";
import DialogBody from "../dialogs/user/DialogBody";
import DialogSetWeight from "../dialogs/user/DialogSetWeight";

const Menu = () => {
  const context = useContext(UserContext);
  const navigate = useNavigate();
  const [charts, setCharts] = useState([]);
  const jwt = useSelector((state) => state.jwt);
  const dialogBody = useRef();
  const dialogSetWeight = useRef();
  const dialogFavorite = useRef();
  const user = useSelector((state) => state.user); 
  const dispatch = useDispatch();
  const init = () => {
    dispatch(loadUserProfileAction(jwt));
    apiUser.getWeightChart(jwt).then(({ response, error }) => {
      if (response) {
        setCharts(response);
      } else {
        // console.log(error)
         context.notify("برای مشاهده نمودار، ابتدا اطلاعات جسمی خود وارد نمایید", "info");
      }
    });
  };

  useEffect(() => {
    if (jwt) {
      context?.showLogin(false);
      init();
    } else context?.showLogin(true);
  }, [jwt]);
  const logout = () => {
    // apiUser.logout(jwt).then(({response,error})=>{
      // if(response){
        dispatch(removeJWTAction()).then(() => {
          context.notify("شما از پروفایل کاربری خود خارج شدید", "info");
          navigate("/");
        });
      // }
    // })
  
  };
  const onSetWeight = (data) => {
    apiUser.setUserBody(data, jwt).then(({ response, error }) => {
      if (response) {
        init();
      }
     
    });
  };
  return (
    <div
      className="menu p-3 pb-5 mb-5 "
      style={{ backgroundColor: "rgba(245, 248, 254, 1)" }}
    >
      {jwt && (
        <>
          <DialogBody ref={dialogBody} />
          <DialogFavorite ref={dialogFavorite} />
          <DialogSetWeight ref={dialogSetWeight} onSelect={onSetWeight} />
        </>
      )}
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div>{user?.nickname}</div>
        <img src="/images/logo.png" className="logo" />
      </div>
      <div className="bg-white p-3 mt-2">
        <div className="d-flex flex-row align-items-center justify-content-between pb-3">
          <span>تغییرات وزن</span>
          <IconButton
            className="p-1"
            onClick={() => dialogSetWeight?.current?.open()}
            icon={<MdAdd />}
          >
            ثبت وزن جدید
          </IconButton>
        </div>
        <div>
          <Chart charts={charts} />
        </div>
      </div>
        <div>
      <IconButton
        onClick={() => dialogBody?.current?.open()}
        icon={<MdArrowBack className="float-start" size={24} />}
        placement="left"
        className="w-100  bg-white mt-4 p-2"
      >
        ویژگی های جسمی
      </IconButton>
      </div>
      <div>
      <IconButton
        onClick={() => {
          dialogFavorite?.current?.open();
        }}
        icon={<MdArrowBack className="float-start" size={24} />}
        placement="left"
        className="w-100  bg-white mt-2 p-2 mb-2"
      >
        غذاهای مورد علاقه
      </IconButton>
      </div>
      <div>
      <a target={"_blank"} href="https://healymealy.com/blog">
        <IconButton
          icon={<MdArrowBack className="float-start" size={24} />}
          placement="left"
          className="w-100  bg-white mt-5 p-2"
        >
          بلاگ هیلی میلی
        </IconButton>
      </a>
      </div>
      <div>
      <a  target={"_blank"} href="https://healymealy.com/about-us/">
        <IconButton
          icon={<MdArrowBack className="float-start" size={24} />}
          placement="left"
          className="w-100  bg-white mt-2 p-2"
        >
          درباره ما
        </IconButton>
      </a>
      </div>
      <div>
      <a  target={"_blank"} href="https://healymealy.com/contact-us/">
        <IconButton
          icon={<MdArrowBack className="float-start" size={24} />}
          placement="left"
          className="w-100  bg-white mt-2 p-2"
        >
          تماس با ما
        </IconButton>
      </a>
      </div>
    
      <IconButton
        onClick={logout}
        className={"text-center m-auto d-block   mb-5 mt-2"}
        icon={<MdLogout className="float-start" size={24} />}
      />
     
    </div>
  );
};

export default Menu;
