import httpService from "../services/httpService";
import Global from "../config.json";

const apiFood = {
  async loadFoodLogging(date, jwt) {
    let res = { response: null, error: null };

    let resStatus = 0;
    await httpService
      .get(`${Global.HealyMealy}foodlogging/detail/?date=${date}`, {
        headers: jwt,
      })
      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 201:
          case 200:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 400:
          case 401:
          case 404:
            res.error =error.response.data;
            break;
        }
      });
     
    return res;
  },
  async searchFood(text, jwt) {
    let res = { response: null, error: null };

    let resStatus = 0;
    await httpService
      .get(`${Global.HealyMealy}global/search/?q=${text}`, {
        headers: jwt,
      })
      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 201:
          case 200:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 400:
          case 401:
          case 404:
            res.error =error.response.data;
            break;
        }
      });
     
    return res;
  },
  async loadUnits({item,type}) {
    let res = { response: null, error: null };
    await httpService
      .get(`${Global.HealyMealy}foodlogging/unit?item=${type}&id=${item.id}`)
      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 201:
          case 200:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 400:
          case 401:
          case 404:
            res.error =error.response.data;
            break;
        }
      });
     
    return res;
  },
  async insertFoodLogging(form,jwt) {
    let res = { response: null, error: null };
    await httpService
      .post(`${Global.HealyMealy}foodlogging/`,
      JSON.stringify(
       {...form}
      ),
      {
        headers:jwt
      })
      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 201:
          case 200:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 400:
          case 401:
          case 404:
            res.error =error.response.data;
            break;
        }
      });
     
    return res;
  },
  async editFoodLogging(flid,form,jwt) {
    let res = { response: null, error: null };
    await httpService
      .put(`${Global.HealyMealy}foodlogging/detail/${flid}/`,
   
       form
      ,
      {
        headers:jwt
      })
      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 201:
          case 200:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 400:
          case 401:
          case 404:
            res.error =error.response.data;
            break;
        }
      });
     
    return res;
  },
  async deleteFoodLogg(id,jwt) {
    let res = { response: null, error: null };
    await httpService
      .delete(`${Global.HealyMealy}foodlogging/detail/${id}/`,
      
      {
        headers:jwt
      })
      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 201:
          case 200:
            case 204:
            res.response = 204;
            break;
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 400:
          case 401:
          case 404:
            res.error =error.response.data;
            break;
        }
      });
     
    return res;
  },
  async loadFood(fid, jwt) {
    let res = { response: null, error: null };
    await httpService
      .get(`${Global.HealyMealy}recipe/${fid}/`, {
        headers: jwt,
      })
      .then((json) => {
        console.log(json,"loadfood");
        switch (json.status) {
          case 201:
          case 200:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 400:
          case 401:
          case 404:
            res.error =error.response.data;
            break;
        }
      });
     
    return res;
  },
};
export default apiFood;
