import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, ButtonGroup, Modal, Panel, SelectPicker, Steps } from "rsuite";
import Ruler from "rc-ruler";
import "rc-ruler/dist/index.css";
import { MdCheck } from "react-icons/md";
import apiUser from "../../../api/apiUser";
import { useSelector } from "react-redux";
import apiJob from "../../../api/apiJob";
import Question from "../../Home/components/Question";

const DialogQuestion = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const question = useRef();
  const [skip, setSkip] = useState(false)
  const jwt = useSelector((state) => state.jwt);
  const [value, setValue] = useState(1);
  const [step, setStep] = React.useState(0);
  const onChange = (nextStep) => {
    if(skip && nextStep<=2)
    setOpen(false)
    setStep(nextStep);
  };
  useImperativeHandle(ref, () => ({
    open(skip) {
      setSkip(skip)
      if (skip) setStep(3);
      else setStep(0);
      setOpen(true);
    },
  }));
  useEffect(() => {
    apiJob.loadAnswers(jwt).then(({ response, error }) => {
      if (response) setAnswers(response);
    });
    apiJob.loadQuestions(jwt).then(({ response, error }) => {
      if (response) setQuestions(response);
    });
  }, []);
  const onNext = async () => {
    setIsLoading(true);
    answers[step] = await question.current.onSubmit();
    console.log(answers[step]);
    setAnswers(answers);
    onChange(step + 1);
    setIsLoading(false);
  };
  const onFinish = async () => {
    setIsLoading(true);
    answers[step] = await question.current.onSubmit(true);
    console.log(answers[step]);
    setAnswers(answers);
    setIsLoading(false);
    props.onSuccess();
    setOpen(false);
  };
  const onPrevious = () => onChange(step - 1);
  const checkTitle = () => {
    switch (questions[step]?.category) {
      case "job":
        return "میزان فعالیت شغلی";
      case "commuting":
        return "میزان فعالیت مرتبط با رفت و آمد ";
      case "House_chores":
        return "  میزان فعالیت  بدنی مرتبط با امور  داخل منزل، حیاط و خارج از منزل";
      case "free_time":
        return "میزان فعالیت  بدنی مرتبط با ورزش و اوقات فراغت";
      default:
        return "زمان صرف شده در حالت";
    }
  };
  const checkStep = () => {
    switch (questions[step]?.category) {
      case "job":
        return 0;
      case "commuting":
        return 1;
      case "House_chores":
        return 2;
      case "free_time":
        return 3;
      default:
        return 4;
    }
  };
  const checkAnswers = () => {
    let _answer = answers.filter((a) => a.question == questions[step]?.id);
    return _answer[0];
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ height: "100vh" }}
    >
      <Modal.Header>بررسی میزان فعالیت روزانه</Modal.Header>

      <Modal.Body>
        <Steps current={checkStep()}>
          <Steps.Item />
          <Steps.Item />
          <Steps.Item />
          <Steps.Item />
          <Steps.Item />
        </Steps>
        <hr />
        <Panel header={checkTitle()}>
          <Question
            ref={question}
            data={questions[step]}
            step={step}
            answer={checkAnswers()}
            setAnswer={setAnswers}
          />
        </Panel>
        <hr />
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button onClick={onPrevious} disabled={step === 0}>
            قبلی
          </Button>

          {step !== questions.length - 1 ? (
            <Button onClick={onNext} loading={isLoading}>
              بعدی
            </Button>
          ) : (
            <Button onClick={onFinish} loading={isLoading}>
              اتمام
            </Button>
          )}
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
});

export default DialogQuestion;
