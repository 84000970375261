import React, { forwardRef, useImperativeHandle, useState ,useContext} from "react";
import { MdCheck } from "react-icons/md";
import { Input, InputNumber, Modal } from "rsuite";
import UserContext from "../../../context/UserContext";
import moment from "jalali-moment";
import apiMeals from "../../../api/apiMeals";
import { useSelector } from "react-redux";
const DialogMealConfig = forwardRef((props, ref) => {
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [meals, setMeals] = useState([]);
  const context=useContext(UserContext)
  const jwt=useSelector(state=>state.jwt)
  const [,forceUpdate]=useState()
  useImperativeHandle(ref, () => ({
    open(data) {
      setOpen(true);
      setMeals(data);
      console.log(Object.keys(data));
    },
  }));
  const handleSubmit=()=>{
    let _meals = []
    // context.isLoading(true)
    Object.keys(meals).map(item => {
        _meals.push({
            id: meals[ item ].id,
            from_time: moment(meals[ item ].from, "HH").format("H:mm"),
            to_time: moment(meals[ item ].to, "HH").format("H:mm"),
        })
    })
    // console.log(_meals);
    apiMeals.changeMeals(_meals,jwt)
    .then(({ response, error }) => {
        console.log(response, error)
        if (response) {
            props.onSuccess()
            setOpen(false)
        }
        else if (error)
            context.notify( error?.detail, 'error');
       

    }).finally(() => context.isLoading(false))
  }
  return (
    <div>
      <Modal backdrop={true} keyboard={true} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title className="text-center">
            ویرایش اطلاعات وعده
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>

          { Object.keys(meals).length > 0 && Object.keys(meals).map((item, index) => { 
            
              return(
            <div key={index} className="card">
              <div className="card-body ">
                <div className="form-group">
                  <label>نام وعده</label>
                  <Input disabled value={item} />
                  <div className="d-flex justify-content-between align-items-center meal-time">
                    <span >زمان وعده</span>
                    <span className="d-flex align-items-center justify-content-around">
                      <InputNumber
                       style={{width:"25%"}}
                        defaultValue={meals[item].from.split(":")[0]}
                        onChange={ (value) => { meals[ item ].from = value;forceUpdate(1);  } }
                         />{" "} تا {" "}
                      <InputNumber 
                      style={{width:"25%"}} 
                      onChange={ (value) => { meals[ item ].to = value;forceUpdate(1);  } }
                      defaultValue={meals[item].to.split(":")[0]} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
              )
          })}
        </Modal.Body>
        <Modal.Footer>
            <button type="submit" onClick={handleSubmit} className="btn-submit">
              <MdCheck color="white" size={24} />
            </button>
          </Modal.Footer>
      </Modal>
    </div>
  );
});
export default DialogMealConfig;
