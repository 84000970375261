import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import WheelPicker from "react-simple-wheel-picker";

import { Modal } from "rsuite";
import Ruler from "rc-ruler";
import "rc-ruler/dist/index.css";
import apiUser from "../../../api/apiUser";
import { useSelector } from "react-redux";
import { MdCheck } from "react-icons/md";

const DialogSetWeight = forwardRef((props, ref) => {
  
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState("");
  const [userKG, setUserKG] = useState(80);
  const [userKG2, setUserKG2] = useState(80);
  const [userGR, setUserGR] = useState(0);
  useImperativeHandle(ref, () => ({
    open(weight) {
      setOpen(true);
      if(!weight) weight=80.00
      let [kg, gr] = weight.toFixed(2).toString().split(".");
      // console.log(kg, gr, 777);
      setUserGR(Number(gr));
      setUserKG(Number(kg));
    },
  }));

  const handleSubmit = () => {
    setOpen(false);
    props.onSelect({ weight: Number(`${userKG.value}.${userGR.value}`) });
  };
  const handleKGOnChange = (value) => {
      console.log(value)
    setUserKG(value);
  };
  const handleGROnChange = (value) => {
    setUserGR(value);
  };
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Modal.Header>
        <h4>ثبت وزن جدید</h4>
      </Modal.Header>
     <Modal.Body
        style={{ direction: "ltr" }}
        className="weight d-flex justify-content-center align-items-center"
      >
         
        <p>کیلو گرم</p>
      
        <WheelPicker
          data={global.KG}
          onChange={handleKGOnChange}
          height={150}
          width={50}
          
          itemHeight={30}
          selectedID={userKG}
          color="#ccc"
          activeColor="#333"
          backgroundColor="#fff"
        />
        <h3>.</h3>
        <WheelPicker
          data={global.GR}
          onChange={handleGROnChange}
          height={150}
          width={50}
          titleText="Enter value same as aria-label"
          itemHeight={30}
          selectedID={userGR}
          color="#ccc"
          activeColor="#333"
          backgroundColor="#fff"
        />
      </Modal.Body>

      <Modal.Footer>
          <p class="dialog-information">
          بهترین زمان پیشنهادی برای اندازه‌گیری وزن با ترازو صبح پس از بیدار شدن از خواب و رفتن به دستشویی است زیرا در این حالت مثانه نیز تخلیه شده است و عواملی مانند ورزش روز قبل و آنچه که دیروز خورده‌اید نیز تاثیر معنی‌داری در نشان دادن مقدار وزن شما نخواهند داشت.

          </p>
        <button onClick={handleSubmit} className="btn-submit">
          <MdCheck color="white" size={24} />
        </button>
      </Modal.Footer>
    </Modal>
  );
});

export default DialogSetWeight;
