import Global from "../config.json";
import httpService from "../services/httpService";
const apiJob={
async loadJobs(){
    let res = { response: null, error: null };

    let resStatus = 0;
    await httpService
      .get(`${Global.HealyMealy}global/job/`)
      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 201:
          case 200:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 400:
          case 401:
          case 404:
            res.error =error.response.data;
            break;
        }
      });
     
    return res;
},
async loadUserJob(jwt){
    let res = { response: null, error: null };

    let resStatus = 0;
    await httpService
      .get(`${Global.HealyMealy2}user/job/`,
      {
          headers:jwt
      })
      .then((json) => {
        console.log(json,77777777777777777777777);
        switch (json?.status) {
          case 201:
          case 200:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        console.log(error,888888888888)
        switch (error.response.status) {
          case 400:
          case 401:
          case 404:
            res.error =error.response.data;
            break;
        }
      });
     
    return res;
},
async setJob(jid,jwt){
  let res = { response: null, error: null };

  await httpService
    .post(`${Global.HealyMealy2}user/job/`,{job:jid},
    {
        headers:jwt
    })
    .then((json) => {
      console.log(json);
      switch (json.status) {
        case 201:
        case 200:
          res.response = json.data;
          break;
      }
    })
    .catch((error) => {
      switch (error.response.status) {
        case 400:
        case 401:
        case 404:
          res.error =error.response.data;
          break;
      }
    });
   
  return res;
},
async loadQuestions(jwt){
  let res = { response: null, error: null };

  await httpService
    .get(`${Global.HealyMealy}regimen/activity/question/`,
    {
        headers:jwt
    })
    .then((json) => {
      console.log(json,77777777777777777777777);
      switch (json?.status) {
        case 201:
        case 200:
          res.response = json.data;
          break;
      }
    })
    .catch((error) => {
      console.log(error,"error questions")
      switch (error.response.status) {
        case 400:
        case 401:
        case 404:
          res.error =error.response.data;
          break;
      }
    });
   
  return res;
},
async setAnswer(qid,mins,days,finish,jwt){
  let res = { response: null, error: null };

  await httpService
    .post(`${Global.HealyMealy}regimen/activity/answer/`,{days:days,minutes:mins,question:qid,finish:finish},
    {
        headers:jwt
    })
    .then((json) => {
      console.log(json);
      switch (json.status) {
        case 201:
        case 200:
          res.response = json.data;
          break;
      }
    })
    .catch((error) => {
      switch (error.response.status) {
        case 400:
        case 401:
        case 404:
          res.error =error.response.data;
          break;
      }
    });
   
  return res;
},
async loadAnswers(jwt){
  let res = { response: null, error: null };

  await httpService
    .get(`${Global.HealyMealy}regimen/activity/answer/`,
    {
        headers:jwt
    })
    .then((json) => {
      console.log(json);
      switch (json.status) {
        case 201:
        case 200:
          res.response = json.data;
          break;
      }
    })
    .catch((error) => {
      switch (error.response.status) {
        case 400:
        case 401:
        case 404:
          res.error =error.response.data;
          break;
      }
    });
   
  return res;
},
}
export default apiJob;