import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useSelector } from 'react-redux'
import { InputNumber } from 'rsuite'
import apiJob from '../../../api/apiJob'

const Question =forwardRef ((props,ref) => {
  const [days, setDays] = useState(props.answer?.days??0)
  const [minutes, setMinutes] = useState(props.answer?.minutes??0)
  const jwt=useSelector(state=>state.jwt)
  useEffect(()=>{
    setDays(props.answer?.days??0)
    setMinutes(props.answer?.minutes??0)
  },[props.step])
  useImperativeHandle(
    ref,
    ()=>({ 
      async onSubmit(finish=false){
      return  await apiJob.setAnswer(props.data.id,days,minutes,finish,jwt)
        .then(({response,error})=>{
          if(response)
          return {question:props.data.id,days:days,minutes:minutes}
          else
        return {question:0,days:0,minutes:0}

        })
      }
    }),
  )
  return (
    <>
    <div>
      <p>{props.data.text}</p>
    </div>
    { props.step <12?
    <div className='d-f-j-a mt-4'>
        <label htmlFor="">روزهای فعالیت (در بازه 1 تا 7 روز)</label>
        <InputNumber style={{width:"30%"}} value={days} max={7} min={0} onChange={setDays} />
   
    </div>
         :null
        }
    
    <div className='d-f-j-a mt-4'>
        <label htmlFor=""> زمان سپری شده (به دقیقه)</label>
        <InputNumber style={{width:"30%"}} value={minutes}  onChange={setMinutes} />
     
     

    </div>
    </>
  )
}
)
export default Question