import React, { forwardRef, useImperativeHandle, useState } from "react";
import { List, Modal } from "rsuite";

const DialogValues = forwardRef(({ nutritive_values },ref) => {
  const [open, setOpen] = useState(false);
  useImperativeHandle(ref,()=>({
      open(){
          setOpen(true)
      }
    }))
  return (
    <div className="modal-container">
      <Modal keyboard={true} open={open} onClose={() => setOpen(false)}>
        <Modal.Header >
          <Modal.Title>ارزش مواد غذایی</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <List bordered hover size={"lg"}>
            <List.Item className="pb-4">
              <span className="float-end ">کالری</span>
              <span className="float-start ">
                {" "}
                {nutritive_values?.calories}
              </span>
            </List.Item>
            <List.Item className="pb-4">
              <span className="float-end ">چربی</span>
              <span className="float-start ">
                {" "}
                {nutritive_values?.total_fat} گرم{" "}
              </span>
            </List.Item>
            <List.Item className="pb-4">
              <span className="float-end ">کربوهیدرات</span>
              <span className="float-start ">
                {" "}
                {nutritive_values?.carbohydrate} گرم{" "}
              </span>
            </List.Item>

            <List.Item className="pb-4">
              <span className="float-end ">پروتئین</span>
              <span className="float-start ">
                {" "}
                {nutritive_values?.protein} گرم{" "}
              </span>
            </List.Item>

            <List.Item className="pb-4">
              <span className="float-end ">فیبر</span>
              <span className="float-start ">
                {nutritive_values?.dietary_fiber} گرم{" "}
              </span>
            </List.Item>

          
          </List>
        </Modal.Body>
      </Modal>
    </div>
  );
});

export default DialogValues;
