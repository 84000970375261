import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { MdCheck } from "react-icons/md";
import { useSelector } from "react-redux";
import { Input, Modal } from "rsuite";
import SimpleReactValidator from "simple-react-validator";
import apiComments from "../../../api/apiComments";
import UserContext from "../../../context/UserContext";

const DialogInsertComment = forwardRef((props, ref) => {
  const [text, setText] = useState("");
  const [, forceUpdate] = useState();
  const [open, setOpen] = useState(false);
  const jwt = useSelector((state) => state.jwt);
  const context = useContext(UserContext);
  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
    },
  }));
  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        required: "پر کردن این فیلد الزامی میباشد",
        max: "حداکثر تا 700 کارکتر میتونی بنویسی",
        text: " متن نوشته شده صحیح نمی باشد",
      },
      element: (message) => <div style={{ color: "red" }}>{message}</div>,
    })
  );
  const handleSubmit = (event) => {
    event?.preventDefault();

    try {
      if (validator.current.allValid()) {
        apiComments
          .insertComment(props.food.id, text, jwt)
          .then(({ response, error }) => {
            if (response) {
              context.notify("نظر شما برای رسپی مورد نظر ثبت شد", "success");
              props.onSuccess()
         
              setOpen(false);
              setText("")

            }
          });
      } else {
        validator.current.showMessages();
        forceUpdate(1);
      }
    } catch (ex) {
      context.notify("مشکلی پیش آمده لطفا بعدا تلاش کنید.", "error");
      console.log(ex);
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title>نظر</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group">
              <label> نظر خودتو وارد کن</label>
              <Input
                as="textarea"
                rows={4}
                placeholder="اینجا میتونی نظر خودتو بنویسی..."
                value={text}
                onChange={(value) => {
                  setText(value);
                  validator.current.showMessageFor("text");
                }}
              />

              {validator.current.message("text", text, "required|max:700")}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" onClick={handleSubmit} className="btn-submit">
              <MdCheck color="white" size={24} />
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
});

export default DialogInsertComment;
