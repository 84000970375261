import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal, SelectPicker } from "rsuite";
import Ruler from "rc-ruler";
import "rc-ruler/dist/index.css";
import { MdCheck } from "react-icons/md";
import apiUser from "../../../api/apiUser";
import { useSelector } from "react-redux";
import moment from "jalali-moment";
const DialogWater = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const jwt = useSelector((state) => state.jwt);
  const [value, setValue] = useState(1);
  const [date, setDate] = useState(moment());
  useImperativeHandle(ref, () => ({
    open(date) {
      setOpen(true);
      setDate(date);
    },
  }));

  const handleSubmit = () => {
    apiUser.setWater(value, date, jwt).then(({ response, error }) => {
      if (response) {
        console.log(response);
        setValue(value);
        setOpen(false);
        props.setTotalWater(value);
      }
    });
  };
  const data = [
    { value: 1, label: "یک لیوان" },
    { value: 2, label: "دو لیوان" },
    { value: 3, label: "سه لیوان" },
    { value: 4, label: "چهار لیوان" },
    { value: 5, label: "پنج لیوان" },
    { value: 6, label: "شش لیوان" },
    { value: 7, label: "هفت لیوان" },
    { value: 8, label: "هشت لیوان" },
    { value: 9, label: "نه لیوان" },
    { value: 10, label: "ده لیوان" },
    { value: 11, label: "یازده لیوان" },
    { value: 12, label: "دوازده لیوان" },
    { value: 13, label: "سیزده لیوان" },
  ];
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Modal.Header>تنظیم آب روزانه</Modal.Header>

      <Modal.Body>
        <div className="form-group">
          <label htmlFor="water" className="w-100 fw-bold text-end pb-1">
            لطفا مشخص کنید در طی روز چند لیوان آب می نوشید
          </label>
          <SelectPicker
            onChange={(v, e) => setValue(v)}
            defaultValue={props.total}
            data={data}
            className={"w-100"}
            placeholder={"انتخاب کنید"}
            name="water"
            id="water"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleSubmit} className="btn-submit mt-3">
          <MdCheck color="white" size={24} />
        </button>
      </Modal.Footer>
    </Modal>
  );
});

export default DialogWater;
