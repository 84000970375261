import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "jalali-moment";
import { useDispatch } from "react-redux";
// ChartJS.defaults.font = {
//     /* Can be set to anything */
//     //family: 'Arial',
//   }
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
 
  defaults: {
    font: {
      family: "Dana",
    },
  },
  responsive: true,
  // maintainAspectRatio: false ,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        font: {
          family: "Dana",
        },
      },
    },
    title: {
      display: false,
      text: " Line Chart",
    },
  },
};

export default function Chart(props) {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setData({
      labels:
        props.charts?.weight_chart?.length > 0
          ? props.charts.weight_chart.map((chart) =>
              moment(chart.created__date, "YYYY-MM-DD")
                .locale("fa")
                .format("jD jMMMM")
            )
          : [],
      datasets: [
        {
          label: "وزن شما",
          data:
            props.charts?.weight_chart?.length > 0
              ? props.charts.weight_chart.map((chart) => chart.weight)
              : [0],
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 1.0)",
        },
        {
          label: "وزن مطلوب",
          data: Array.from(
            { length: props.charts.weight_chart?.length ?? 1 },
            (_, i) => props.charts.optimal_weight
          ),
          borderColor: "rgb(52, 116, 235)",
          backgroundColor: "rgb(52, 116, 235)",
        },
        {
          label: "وزن ایده آل",
          data: Array.from(
            { length: props.charts.weight_chart?.length ?? 1 },
            (_, i) => props.charts.ideal_weight
          ),
          borderColor: "rgb(119,221,119)",
          backgroundColor: "rgb(119,221,119)",
        },
      ],
    });
  }, [props.charts]);
  return (
    <div style={{height:200}}>
      {data?.datasets?.length > 0 && <Line height={140} width={200}  options={options}  data={data} />}
    </div>
  );
}
