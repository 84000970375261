import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { MdForum, MdStar, MdStarBorder, MdStarHalf } from "react-icons/md";
import { useSelector } from "react-redux";
import { Button, IconButton, Modal } from "rsuite";
import apiComments from "../../../api/apiComments";
import DialogRate from "../../dialogs/food/DialogRate";
import DialogInsertComment from "../../dialogs/food/DialogInsertComment";
import UserContext from "../../../context/UserContext";

const Comments = (props) => {
  const jwt = useSelector((state) => state.jwt);
  const [open, setOpen] = React.useState(false);
  const [textReport, setTextReport] = useState("")
  const context=useContext(UserContext)
  const handleClose = () => setOpen(false);
  const [comments, setComments] = useState([]);
  const dialogRate=useRef()
  const dialogInsertComment=useRef()
  const loadComments=()=>{
    apiComments.loadComments(props.food.id, jwt).then(({ response, error }) => {
        if (response) setComments(response);
      });
  }
  useEffect(() => {
    if(props.food)
      loadComments()
  }, [props?.food]);
  const report=(text)=>{
    setTextReport(text)
    setOpen(true)
  }
  const handleYes=()=>{
    context.notify("گزارش شما ارسال شد","info")
    setOpen(false)
  }
  return (
    <div style={{height:"auto",maxHeight:400,overflowY:"auto",}} >
        <DialogRate onSuccess={loadComments} ref={dialogRate} food={props.food}/>
        <DialogInsertComment ref={dialogInsertComment} onSuccess={loadComments} food={props.food}/>
      <div className="d-flex align-items-center justify-content-between">
        <h6>امتیاز و نظرات</h6>
        <div className="d-flex align-items-center gap-3 mb-3">
          <IconButton onClick={()=>dialogInsertComment.current.open()} icon={<MdForum size={24} />} circle />
          <IconButton onClick={()=>dialogRate.current.open()} icon={<MdStarHalf  size={24} />} circle />
        </div>
      </div>
      {comments.length? comments.map((comment, index) => {
        return (
          <div key={index} className="p-2">
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-bold">
                {comment?.user?.nickname == ""
                  ? comment?.user?.username
                  : comment?.user?.nickname}
              </span>
              <span>
                {[...Array(parseInt(comment?.rate))].map((x, i) => (
                  <MdStar />
                ))}
                {[...Array(Math.abs(parseInt(comment?.rate) - 5))].map(
                  (x, i) => (
                    <MdStarBorder />
                  )
                )}
              </span>
            </div>
            <p>
                {comment.text}
            </p>
            <div>
            <Button onClick={()=>report(comment.text)} appearance="link">
            گزارش تخلف
              </Button>
            
            </div>
          </div>
        );
      })
    :
    <div className="text-center p-5">
        <h6>نظری در مورد این رسپی ثبت نشده است</h6>
        </div>
    }



<Modal open={open}  onClose={()=>setOpen(false)}>
        <Modal.Header>
          <Modal.Title>گزارش تخلف</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <h6>آیا از ارسال گزارش این پیام مطمین هستید؟</h6>
         <label htmlFor="" >متن گزارش شده:</label>
         <p>
           {textReport}
         </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleYes} appearance="primary">
            بله
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            خیر
          </Button>
        </Modal.Footer>
      </Modal>





    </div>
  );
};

export default Comments;
