import React from 'react'
import logo from "../../assets/loading.gif";

 const Loading=()=>{
  return (
    <div className='w-100' style={{display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:"rgba(255,255,255,0.99)",width:"100%",zIndex:9999,position:"fixed",top:0,bottom:0,left:0,right:0,margin:"auto",textAlign:"center"}}>
       <div class="spinner-border "  role="status">
    <span class="visually-hidden">در حال بارگذاری...</span>
  </div>
      </div>
   
    )
}
export default  Loading;