import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { setDate } from "rsuite/esm/utils/dateUtils";
import apiUser from "../../../api/apiUser";
import Chart from "../../common/Chart";
import DialogBmi from "../../dialogs/bmi/DialogBmi";
import DialogWater from "../../dialogs/user/DialogWater";
import moment from "jalali-moment";
import UserContext from "../../../context/UserContext";

const Information = (props) => {
  const dialogBmi=useRef()
  const dialogWater=useRef()
  const jwt = useSelector((state) => state.jwt);
  const [totalWater, setTotalWater] = useState(8)
  const [water, setWater] = useState(0)
  const [charts, setCharts] = useState([]);
  const [date, setDate] = useState(moment())
  const userdata = useSelector((state) => state.userdata);
  const context =useContext(UserContext)

  const init = (date) => {
    apiUser.getWeightChart(jwt).then(({ response, error }) => {
      if (response) {
        setCharts(response);
      } else {
        // context.notify(error.detail, "error");
      }
    });
  
      apiUser.getWater(date,jwt).then(({ response, error }) => {
        if (response) {
          console.log(response);
          setWater(Number(response.today));
          setTotalWater(Number(response.total));
        }
      });
  
  };
  const addWater=()=>{
    if(water==totalWater)
       context.notify("به هدف نوشیدن آبت رسیدی، همین طور ادامه بده قهرمان!","info")
    apiUser.addWater(date,jwt)
    .then(({response,error})=>{
      if(response)
        setWater(water+1)
    })  
  }
  useEffect(() => {
    setDate(props.selectedDate.locale("en").format("YYYY-MM-DD"))
    init(props.selectedDate.locale("en").format("YYYY-MM-DD"));
  }, [jwt,props.selectedDate]);
  return (
    <div className="information m-2 p-1">

      <h6>مصرف آب</h6>
      <h6>نمودار وزن</h6>

      <div className="box-water" onClick={addWater}>
        <div className="water">
          <div className="fill-water" style={{ maxHeight: "100%",borderRadius: 10,height:  `${Number(water)*100/Number(totalWater)}%` }}></div>
          <img className="icon" src="/images/icons/plus.svg" />
          <span className="amount"> {water} از {totalWater}</span>
        </div>
        <button onClick={()=>dialogWater.current.open(date)}>
        <img  className="ps-2" src="/images/icons/trash.svg" alt="" />
            تعداد</button>

      </div>
      <div className="chart">
        <Chart charts={charts} />
        <button onClick={()=>{dialogBmi.current.open()}}>
            <img  className="ps-2" src="/images/icons/chart.svg" alt="" />
            بررسی نمودار</button>
      </div>
      <DialogBmi ref={dialogBmi} />
      <DialogWater total={totalWater} ref={dialogWater} setTotalWater={setTotalWater} />

    </div>
  );
};

export default Information;
