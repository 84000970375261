import httpService from "../services/httpService";
import Global from "../config.json";
const apiFoods = {
  async loadNewFoods() {
    let res = [];
    await httpService
      .get(`${Global.HealyMealy}recipe/last/`)
      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 201:
          case 200:
            res = json.data;
            break;
        }
      })
      .catch((error) => {
        //todo
      });

    return res;
  },
  async loadFavoriteFoods() {
    let res = [];
    await httpService
      .get(`${Global.HealyMealy}recipe/favorite/`)
      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 201:
          case 200:
            res = json.data;
            break;
        }
      })
      .catch((error) => {
        //todo
      });

    return res;
  },
  async loadTitleOfferFoods() {
    let res = [];
    await httpService
      .get(`${Global.HealyMealy}offer/recipe-title/`)
      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 201:
          case 200:
            res = json.data.display_name;
            break;
        }
      })
      .catch((error) => {
        //todo
      });

    return res;
  },
  async loadOfferFoods() {
    let res = [];
    await httpService
      .get(`${Global.HealyMealy}recipe/offer/`)
      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 201:
          case 200:
            res = json.data;
            break;
        }
      })
      .catch((error) => {
        //todo
      });

    return res;
  },
  async loadCategoryFoods(catid, page) {
    let res = [];
    await httpService
      .get(`${Global.HealyMealy}recipe/?page_size=8&page=${page}&tags=${catid}`)
      .then((json) => {
        console.log(json);
        switch (json.status) {
          case 201:
          case 200:
            res = json.data;
            break;
        }
      })
      .catch((error) => {
        //todo
      });

    return res;
  },
  async loadRelatedFoods(fid, jwt) {
    let res = [];
    await httpService
      .get(`${Global.HealyMealy}recipe/clip/?id=${fid}`, {
        headers: jwt,
      })
      .then((json) => {
        console.log(json,99);
        switch (json.status) {
          case 201:
          case 200:
            res = json.data.slice(0, 7);
            break;
        }
      })
      .catch((error) => {
        //todo
      });

    return res;
  },
  async loadFavoriteFoods( jwt) {
    let res = [];
    await httpService
      .get(`${Global.HealyMealy}recipe/favorite/`, {
        headers: jwt,
      })
      .then((json) => {
        console.log(json,99);
        switch (json.status) {
          case 201:
          case 200:
            res = json.data;
            break;
        }
      })
      .catch((error) => {
        //todo
      });

    return res;
  },
};
export default apiFoods;
