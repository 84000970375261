import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Link } from "react-router-dom";
import { List, Modal } from "rsuite";

const ShowAll = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const handleClose = () => setOpen(false);
  useImperativeHandle(ref, () => ({
    open(data) {
      setOpen(true);
      setData(data);
    },
  }));
  return (
    <Modal backdrop={true} keyboard={true} open={open} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title className="text-center">
         نمایش همه
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <List>
          {data.map((item, index) => (
            <List.Item key={index} index={index}>
                <Link to={`/food/${item.id}`}>
              {item.name}
              </Link>
            </List.Item>
          ))}
        </List>
      </Modal.Body>
    </Modal>
  );
});

export default ShowAll;
