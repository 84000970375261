import httpService from "../services/httpService";
import Global from "../config.json";
const apiComments={
    async loadComments( fid,jwt) {
        let res = { response: null, error: null };
        let resStatus = 0;
        await httpService
          .get(
            `${Global.HealyMealy}recipe/${fid}/comment/`,
            {
              headers: jwt,
            }
          )
    
          .then((json) => {
            console.log(json, resStatus);
            switch (json.status) {
              case 200:
              case 201:
                res.response = json.data;
                break;
            }
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
              case 401:
                res.error = 400;
                break;
            }
          });
    
        return res;
      },
      async insertRate( fid,data,jwt) {
        let res = { response: null, error: null };
        let resStatus = 0;
        await httpService
          .put(
            `${Global.HealyMealy}recipe/${fid}/rate/`,data,
            {
              headers: jwt,
            }
          )
    
          .then((json) => {
            console.log(json, resStatus);
            switch (json.status) {
              case 200:
              case 201:
                res.response = json.data;
                break;
            }
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
              case 401:
                res.error = 400;
                break;
            }
          });
    
        return res;
      },
      async insertComment( fid,text,jwt) {
        let res = { response: null, error: null };
        let resStatus = 0;
        await httpService
          .post(
            `${Global.HealyMealy}recipe/${fid}/comment/`,
              JSON.stringify({text:text})
            ,
            {
              headers: jwt,
            }
          )
    
          .then((json) => {
            console.log(json, resStatus);
            switch (json.status) {
              case 200:
              case 201:
                res.response = json.data;
                break;
            }
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
              case 401:
                res.error = 400;
                break;
            }
          });
    
        return res;
      },
}
export default apiComments;