import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import Item from "../../common/Item";
import { Placeholder } from "rsuite";

const ListRecipeHorizontal = (props, ref) => {
  const H = 146;
  const W = 226;
  // useEffect(() => {
  //   console.log(props?.data, 666666666666);
  // },[props?.data]);
  return (
    <div>
      <h6 className="pt-4">{props?.data?.title}</h6>

      <ScrollingCarousel className="gap-2">
        {props?.data?.length==0
          ? Array.from(Array(5).keys()).map((x, y) => {
              return <Placeholder.Paragraph style={{width:W,height:H}} key={y} graph="square" />;
            })
          : props?.data?.data?.map((item, index) => {
              return (
                <Item
                  key={index}
                  H={H}
                  W={W}
                  index={index}
                  item={item}
                  onTop={props?.onTop}
                />
              );
            })}
      </ScrollingCarousel>
    </div>
  );
};

export default ListRecipeHorizontal;
