import { Notification } from "rsuite";

const message = (msg, type) => {
  let data = {
    info: "اطلاعات",
    success: "تبریک",
    warning: "اخطار",
    error: "خطا",
  };
  return (
    <Notification type={type} header={data[type]} closable>
      <p>{msg}</p>
    </Notification>
  );
};
export default message;
