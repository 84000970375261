import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
  useState,
} from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Loader, Modal } from "rsuite";
import SimpleReactValidator from "simple-react-validator";
import DialogOTP from "./DialogOTP";
import { toast } from "react-toastify";
import ir from "react-phone-input-2/lang/ir.json";

import { MdArrowForwardIos } from "react-icons/md";
import apiUser from "../../../api/apiUser";
import { useSelector } from "react-redux";
import UserContext from "../../../context/UserContext";
const DialogLogin = forwardRef((props, ref) => {
  const jwt = useSelector((state) => state.jwt);
  const [value, setValue] = useState();

  const context = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [, forceUpdate] = React.useState();
  const [mobile, setMobile] = React.useState("");
  const dialogOTP = useRef();
  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        required: "پر کردن این فیلد الزامی میباشد",
        size: "باید 10 عدد  باشد",
        mobile: "شماره همراه نوشته شده صحیح نمی باشد",
        integer: "لطفا فقط عدد وارد نمایید",
      },
      element: (message) => <div style={{ color: "red" }}>{message}</div>,
    })
  );
  const requestOTP = async () => {
    setIsLoading(true);
    apiUser
      .requestOTP("+"+mobile, jwt)
      .then(({ response, error, server }) => {
        if (response) dialogOTP?.current?.open(mobile, response);
        // else if (error) this.context.msgBox("error", "خطا", error, 400);
        // else if (server)
        //   this.context.msgBox(
        //     "error",
        //     "خطا  در ارتباط ",
        //     "خطا در ارتباط با سرور!\nلطفا دوباره امتحان کنید."
        //   );
      })
      .finally(() => setIsLoading(false));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
      console.log(validator.current.allValid())
    try {
      if (validator.current.allValid()) {
        requestOTP();
      } else {
        validator.current.showMessages();
        forceUpdate(1);
      }
    } catch (ex) {
      toast.error("مشکلی پیش آمده.", {
        position: "top-right",
        closeOnClick: true,
      });
      console.log(ex);
    }
  };

  useImperativeHandle(ref, () => ({
    open() {
      setShowDialog(true);
    },
    close() {
      setShowDialog(false);
    },
  }));

  return (
    <div className="modal-container ">
      <DialogOTP
        onSuccess={() => {
          setShowDialog(false);
          props.onSuccess();
        }}
        ref={dialogOTP}
      />

      <Modal backdrop={"static"} keyboard={false} open={showDialog}>
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton={false}>
            <Modal.Title>صفحه ورود</Modal.Title>
          </Modal.Header>

          <Modal.Body className="login-phonenumber ">
            <label className="title">
              برای ادامه کار لطفا شماره تلفن خود را وارد کنید!
            </label>
            <div className="form-group mt-3" style={{ height: 50 }} dir="ltr">
              {/* <label>شمارت رو وارد کن</label> */}
              {/* <input
                type="number"
                className="form-control input"
                value={mobile}
                name="mobile"
                aria-describedby="mobile"
                placeholder="مثال:  ۹۱۲۳۴۵۶۷۸۹"
                onChange={(e) => {
                  setMobile(context.fixNumbers(e.target.value));
                  // console.log()
                  validator.current.showMessageFor("mobile");
                }}
              /> */}

              <PhoneInput
                className="position-absolute"
                placeholder="مثال:  ۹۱۲۳۴۵۶۷۸۹"
                searchStyle={{direction:"rtl"}}
                dropdownStyle={{direction:"ltr"}}
                countryCodeEditable={false}
                // localization={ir}
                inputProps={{
                  name: 'mobile',
                  required: true,
                  autoFocus: true
                }}
                inputStyle={{ width: "91%" }}
                value={mobile}
                enableSearch
                searchPlaceholder="جستجو کشور"
                searchNotFound="  پیدا نشد"
                aria-describedby="mobile"
                country={"ir"}
                onChange={(v) => {
                  setMobile(context.fixNumbers(v));
                  // setMobile(v)
                   console.log(mobile)
                  validator.current.showMessageFor("mobile");
                }}
              />
            
            </div>
            <div>
            {validator.current.message(
                "mobile",
                mobile,
                "required|size:12|integer"
              )}
            </div>
            <div className="form-group mt-2">
              <p>
                {" "}
                <a
                  className="text-primary"
                  href="https://healymealy.com/%d8%b4%d8%b1%d8%a7%db%8c%d8%b7-%d9%88-%d8%b6%d9%88%d8%a7%d8%a8%d8%b7/"
                  target={"_blank"}
                >
                  شرایط استفاده از خدمات و حریم خصوصی{" "}
                </a>{" "}
                را می پذیرم
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn-submit">
              <MdArrowForwardIos color="white" size={24} />
              {isLoading && <Loader />}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
});
export default DialogLogin;
