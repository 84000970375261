import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { InputNumber, Modal, Radio, RadioGroup, Toggle } from "rsuite";
import apiUser from "../../../api/apiUser";
import DialogPickDate from "./DialogPickDate";
import { v4 as uuid } from "uuid";
import moment from "jalali-moment";
import DialogSetHeight from "./DialogSetHeight";
import DialogSetWeight from "./DialogSetWeight";
import DialogSetWrist from "./DialogSetWrist";
import DialogSetWaist from "./DialogSetWaist";
import DialogSetHip from "./DialogSetHip";
import DialogBmi from "../bmi/DialogBmi";
import DialogSetActivity from "./DialogSetActivity";
import apiJob from "../../../api/apiJob";
import UserContext from "../../../context/UserContext";
const DialogBody = forwardRef((props, ref) => {
  const context = useContext(UserContext);
  const jwt = useSelector((state) => state.jwt);
  const [bodyData, setBodyData] = useState(null);
  const [canClose, setCanClose] = useState(false);
  const [job, setJob] = useState(null);
  const [, forceUpdate] = useState(0);
  const [open, setOpen] = useState(false);
  const dialogPickDate = useRef();
  const dialogSetHeight = useRef();
  const dialogSetWeight = useRef();
  const dialogSetWrist = useRef();
  const dialogSetWaist = useRef();
  const dialogSetHip = useRef();
  const dialogBmi = useRef();
  const dialogActivity = useRef();
  const loadData = async () => {
    await apiUser.getUserBodyData(jwt).then(({ response, error }) => {
      if (response) {
        setBodyData(response);
        if (!checkFilledForm(response) && !open) {
          context.notify("لطفا اطلاعات جسمانی خود را پر کنید", "info");
          setOpen(true);
        }
      }
    });
    apiJob.loadUserJob(jwt).then(({ response, error }) => {
      if (response) {
        let _jobs=response?.job?.map(j=> j?.name);
        setJob(_jobs.join(","));
      }
    });
  };
  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
      loadData();
    },
  }));
  // useEffect(() => {
  //   loadData();
  // }, []);
  const onSelectData = (data = {}) => {
    apiUser
      .setUserBody({ ...data, gender: bodyData?.gender ?? "M" }, jwt)
      .then(({ response, error }) => {
        if (response) {
          setBodyData(response);
        }
      });
  };
  const checkFilledForm = (bodyData) => {
    if (
      !bodyData.height ||
      !bodyData.weight ||
      !bodyData.wrist ||
      // !bodyData.hip ||
      !bodyData.gender ||
      // !bodyData.waist ||
      !bodyData.birth_date
    )
      return false;
    setCanClose(true);
    return true;
  };
  const checkValidation = () => {
    if (!checkFilledForm(bodyData))
      context.notify("اطلاعات جسمی رو کامل پر کن", "error");
    else dialogBmi.current.open();
  };
  return (
    <div className="user-body">
      <DialogPickDate ref={dialogPickDate} onSelect={onSelectData} />
      <DialogSetHeight ref={dialogSetHeight} onSelect={onSelectData} />
      <DialogSetWeight ref={dialogSetWeight} onSelect={onSelectData} />
      <DialogSetWrist ref={dialogSetWrist} onSelect={onSelectData} />
      <DialogSetWaist ref={dialogSetWaist} onSelect={onSelectData} />
      <DialogSetHip ref={dialogSetHip} onSelect={onSelectData} />
      <DialogSetActivity ref={dialogActivity} onSelect={loadData} />
      <DialogBmi ref={dialogBmi} />
      <Modal
        backdrop={true}
        keyboard={true}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Modal.Header className="text-center" closeButton={true}>
          <h4>
            <img src="/images/icons/bmi.svg" width={32} />
            ویژگی های جسمی
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="user-body___item">
            <div>
              <img src="/images/icons/gender.svg" width={32} />
              <span>جنسیت</span>
            </div>
            <div>
              <span>
                <Toggle
                  size="lg"
                  checked={bodyData?.gender == "M" ? true : false}
                  onChange={(value) => {
                    if (value) bodyData.gender = "M";
                    else bodyData.gender = "F";
                    forceUpdate(uuid());
                    onSelectData();
                  }}
                  checkedChildren="مرد"
                  unCheckedChildren="زن"
                />
              </span>
            </div>
          </div>
          {bodyData?.gender == "F" && (
            <div className="user-body___item">
              <div>
                <img src="/images/icons/pregnancy.svg" width={32} />
                <span>آیا در دوران بارداری هستید؟</span>
              </div>
              <RadioGroup
                name="radioList"
                inline
                appearance="picker"
                value={bodyData?.is_pregnancy ?? false}
                onChange={(value) => {
                  if (value) {
                    bodyData.is_pregnancy = value;
                    bodyData.is_breastfeeding = false;
                  } else bodyData.is_pregnancy = value;
                  forceUpdate(uuid());
                  onSelectData({
                    is_pregnancy: value,
                    months_of_pregnancy: bodyData?.months_of_pregnancy ?? 0,
                  });
                }}
              >
                <Radio value={true}>بله</Radio>
                <Radio value={false}>خیر</Radio>
              </RadioGroup>
            </div>
          )}
          {bodyData?.gender == "F" && bodyData?.is_pregnancy && (
            <div className="user-body___item">
              <div>
                <img src="/images/icons/pregnancy.svg" width={32} />
                <span>ماه چندم بارداری هستید؟</span>
              </div>
              <div style={{ width: 60 }}>
                <InputNumber
                  value={bodyData?.months_of_pregnancy ?? 0}
                  onChange={(value) => {
                    bodyData.months_of_pregnancy = value;
                    forceUpdate(uuid());
                    onSelectData({ months_of_pregnancy: value });
                  }}
                  max={9}
                  min={0}
                />
              </div>
            </div>
          )}
          {bodyData?.gender == "F" && !bodyData?.is_pregnancy && (
            <div className="user-body___item">
              <div>
                <img
                  src="/images/icons/feeding.svg"
                  width={32}
                  style={{ padding: 7 }}
                />
                <span>آیا در دوران شیردهی هستید؟</span>
              </div>
              <RadioGroup
                name="radioList"
                inline
                appearance="picker"
                value={bodyData?.is_breastfeeding ?? false}
                onChange={(value) => {
                  if (value) {
                    bodyData.is_breastfeeding = value;
                    bodyData.is_pregnancy = false;
                  } else bodyData.is_breastfeeding = value;
                  forceUpdate(uuid());
                  onSelectData({
                    is_breastfeeding: value,
                    months_of_pregnancy: bodyData?.months_of_pregnancy ?? 0,
                  });
                }}
              >
                <Radio value={true}>بله</Radio>
                <Radio value={false}>خیر</Radio>
              </RadioGroup>
            </div>
          )}
          {bodyData?.gender == "F" &&
            bodyData?.is_breastfeeding &&
            bodyData?.is_pregnancy == false && (
              <div className="user-body___item">
                <div>
                  <img src="/images/icons/pregnancy.svg" width={32} />
                  <span>ماه چندم شیردهی هستید؟</span>
                </div>
                <div style={{ width: 60 }}>
                  <InputNumber
                    value={bodyData?.months_of_pregnancy ?? 0}
                    onChange={(value) => {
                      bodyData.months_of_pregnancy = value;
                      forceUpdate(uuid());
                      onSelectData({ months_of_pregnancy: value });
                    }}
                    max={24}
                    min={0}
                  />
                </div>
              </div>
            )}
          <div className="user-body___item">
            <div>
              <img src="/images/icons/birthday.svg" width={32} />
              <span>تاریخ تولد</span>
            </div>
            <div>
              <span
                role={"button"}
                onClick={() =>
                  dialogPickDate?.current?.open(bodyData.birth_date)
                }
                className="user-body___placeHolder"
              >
                {bodyData?.birth_date
                  ? moment(bodyData?.birth_date, "YYYY-MM-DD").format(
                      "jYYYY/jMM/jDD"
                    )
                  : "_ _ / _ _ / _ _"}
              </span>
            </div>
          </div>
          <div className="user-body___item">
            <div>
              <img src="/images/icons/weight.svg" width={32} />
              <span>وزن</span>
            </div>
            <div
              role={"button"}
              onClick={() => dialogSetWeight.current.open(bodyData?.weight)}
              className="d-flex align-items-center "
            >
              <span>kg</span>
              <span className="user-body___placeHolder">
                {" "}
                {bodyData?.weight ?? "_ _"}
              </span>
            </div>
          </div>
          <div className="user-body___item">
            <div>
              <img src="/images/icons/height.svg" width={32} />
              <span>قد</span>
            </div>
            <div
              role={"button"}
              onClick={() => dialogSetHeight.current.open(bodyData?.height)}
              className="d-flex align-items-center "
            >
              <span>cm</span>
              <span className="user-body___placeHolder">
                {bodyData?.height ?? "_ _"}
              </span>
            </div>
          </div>
          <div className="user-body___item">
            <div>
              <img src="/images/icons/wrist.svg" width={32} />
              <span>دور مچ دست</span>
            </div>
            <div
              role="button"
              onClick={() => dialogSetWrist.current.open(bodyData?.wrist)}
              className="d-flex align-items-center "
            >
              <span>cm</span>
              <span className="user-body___placeHolder">
                {" "}
                {bodyData?.wrist ?? "_ _"}
              </span>
            </div>
          </div>
          <div className="user-body___item">
            <div>
              <img src="/images/icons/hip.svg" width={32} />
              <span>دور باسن</span>
            </div>
            <div
              role={"button"}
              onClick={() => dialogSetHip.current.open(bodyData?.hip)}
              className="d-flex align-items-center "
            >
              <span>cm</span>
              <span className="user-body___placeHolder">
                {bodyData?.hip ?? "_ _"}
              </span>
            </div>
          </div>
          <div className="user-body___item">
            <div>
              <img src="/images/icons/waist.svg" width={32} />
              <span>دور کمر</span>
            </div>
            <div
              role={"button"}
              onClick={() => dialogSetWaist.current.open(bodyData?.waist)}
              className="d-flex align-items-center "
            >
              <span>cm</span>
              <span className="user-body___placeHolder">
                {" "}
                {bodyData?.waist ?? "_ _"}
              </span>
            </div>
          </div>
          <div onClick={() => dialogActivity.current.open()} role="button" className="user-body___item">
            <div style={{whiteSpace:"nowrap"}}>
              <img src="/images/icons/job.svg" width={32} />
              <span>شغل</span>
            </div>
            <div
              
              className="d-flex align-items-center "
            >
              <span className="user-body___placeHolder">
                {job===""?"?":job}
              </span>
            </div>
          </div>
          <div
            className="user-body___item "
            style={{ backgroundColor: "rgba(60, 65, 81, 1)", color: "white" }}
          >
            <div>
              <img
                src="/images/icons/result.svg"
                width={32}
                className="m-2 p-1"
              />
              <span>نتایج</span>
            </div>
            <div role={"button"} onClick={checkValidation}>
              <span className="user-body___placeHolder"> {" > "}</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
});

export default DialogBody;
