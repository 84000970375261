import React, { useEffect, useRef, useState } from "react";
import {
  MdArrowForwardIos,
  MdFavorite,
  MdModeComment,
  MdOutlineFavoriteBorder,
  MdPlayArrow,
  MdShare,
  MdStar,
} from "react-icons/md";
import Global from "../../config.json";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import { Button, IconButton } from "rsuite";
import apiFood from "../../api/apiFood";
import apiFoods from "../../api/apiFoods";
import apiUserFood from "../../api/apiUserFood";
import ListRecipeHorizontal from "../Search/components/ListRecipeHorizontal";
import Comments from "./components/Comments";
import MaterialFood from "./components/MaterialFood";
import TutorialFood from "./components/TutorialFood";
import { RWebShare } from "react-web-share";
import DialogVideo from "../dialogs/food/DialogVideo";

const Food = (props) => {
  const navigate = useNavigate();
  const dialogVideo = useRef();
  const foodScreen = useRef();
  const [food, setFood] = useState(null);
  const [like, setLike] = useState(false);
  const [relatedFoods, setRelatedFoods] = useState([]);
  const { fid } = useParams();
  const jwt = useSelector((state) => state.jwt);
  const loadFood = () => {
    console.log("open food page", fid, jwt);
    apiFood.loadFood(fid, jwt).then(({ response, error }) => {
      if (response) {
        setFood(response);
        setLike(response.favorite);
      }
    });
  };
  const loadRelatedFoods = async () => {
    await apiFoods.loadRelatedFoods(fid, jwt).then((res) => {
      if (res) {
        setRelatedFoods({
          data: res,
          title: "دستورپخت‌هایی که ممکن است دوست داشته باشید ",
        });
      }
    });
  };
  useEffect(() => {
    if (jwt) {
      loadFood();
      loadRelatedFoods();
    }
  }, [ jwt,fid]);
  const foodLike = () => {
    apiUserFood.foodLike(food.id, jwt).then(({ response, error }) => {
      if (response) {
        setLike(!like);
      }
    });
  };
  return (
    <div className="food mb-5 pb-5 " ref={foodScreen}>
      <DialogVideo ref={dialogVideo} food={food} relatedFoods={relatedFoods} />
      <div>
        <div className="d-flex  position-absolute w-100 p-2 justify-content-between align-items-center">
          <IconButton
            onClick={() => {
              navigate(-1);

            }}
            icon={<MdArrowForwardIos size={24} />}
            appearance="default"
            circle
          />

          <div className="float-start toolbar-first">
            <IconButton
              icon={
                like ? (
                  <MdFavorite color="red" size={24} />
                ) : (
                  <MdOutlineFavoriteBorder size={24} />
                )
              }
              appearance="default"
              circle
              onClick={foodLike}
            />
            <RWebShare
              data={{
                text: "این رسپی رو برای دوستات هم بفرست",
                url: `${Global.WebAppUrl}#/food/${food?.id}`,
                title: "اشتراک گذاری",
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <IconButton
                icon={<MdShare size={24} />}
                appearance="default"
                circle
              />
            </RWebShare>
          </div>
        </div>
        {food?.cover && (
          <div
            className="w-100 cover"
            style={{
              backgroundImage: `url('${food?.cover}')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
        )}
      </div>
      <div className="food-data" style={{ marginTop: -30, borderRadius: 10 }}>
        <div className="food-toolbar-second">
          {food?.full_clip?.media && (
            <IconButton
              style={{ backgroudColor: "black" }}
              size="lg"
              onClick={() => dialogVideo.current.open()}
              circle
              icon={<MdPlayArrow size={24} />}
            ></IconButton>
          )}
        </div>
        <div className="p-2">
          <h3>{food?.name}</h3>
          <div className="d-flex justify-content-start gap-3 align-items-center">
            <span>
              <small>{food?.likes}</small>
              <MdFavorite />
            </span>
            <span>
              <small>{food?.comments}</small>
              <MdModeComment />
            </span>
            <span>
              <small>{food?.rates?.count}</small>
              <MdStar />
            </span>
          </div>
          <div className="food-info text-center d-flex  justify-content-between align-items-center">
            <div>
              <p> {food?.time_of_preparing} دقیقه </p>
              <p>آماده سازی</p>
            </div>
            <div>
              <p>{food?.time_of_cooking} دقیقه </p>
              <p>پخت و پز</p>
            </div>
            <div>
              <p> {food?.calories} کیلو کالری</p>
              <p>کالری</p>
            </div>
          </div>

          <MaterialFood food={food} />

          <TutorialFood food={food} />
        {jwt &&  <Comments food={food} /> }
          <ListRecipeHorizontal
            data={relatedFoods ?? []}
            onTop={foodScreen.current}
          />
        </div>
      </div>
    </div>
  );
};

export default Food;
