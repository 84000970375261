import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Modal } from "rsuite";
import Ruler from "rc-ruler";
import "rc-ruler/dist/index.css";
import apiUser from "../../../api/apiUser";
import { useSelector } from "react-redux";
import { MdCheck } from "react-icons/md";

const DialogSetHeight = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(160);
  useImperativeHandle(ref, () => ({
    open(height) {
      setOpen(true);
      setValue(height);
    },
  }));
 
  const handleDragChange = (value) => {
    setValue(value);
  };
  const handleSubmit = () => {
      
     props.onSelect({height:value})
     setOpen(false)
  };
  return (
    <Modal  open={open} onClose={() => setOpen(false)}>
      <Modal.Header>درج قد</Modal.Header>
      <Modal.Body style={{direction:"ltr",overflow: "inherit",
    padding: 10}}>
        <Ruler
          value={value}
          onDrag={handleDragChange}
          start={130}
          end={250}
          step={2}
        />
      </Modal.Body>
      <Modal.Footer>
      <p class="dialog-information">
                            برای اندازه‌گیری صحیح قد به روش زیر عمل کنید:<br/>
                            کفش، زیورآلات، گل‌سر و هر چیزی که به قد شما اضافه می‌کند را از بدن جدا کنید.<br/>
                            وسیله اندازه‌گیری قد را در محلی که فرش یا پوشش کف وجود ندارد، قرار دهید.<br/>
                            صورت و چشم‌ها باید به سمت جلو باشد.<br/>
                            پاها به هم چسبیده باشد.<br/>
                            اطمینان حاصل کنید که زانوها صاف و کف دست‌ها به کنار بدن چسبیده است.<br/>
                            شانه‌ها در یک سطح قرار داشته باشد. حالا صاف بایستید و خط دید را با کف اتاق در یک راستا قرار دهید.<br/>
                            حالا در حالیکه سر، شانه، باسن و پاشنه پای شما با دیوار در تماس است، با یک خط‌کش یا مداد که با سر شما مماس است، بالای سر خود علامت‌گذاری کنید.<br/>
          </p>
        <button onClick={handleSubmit} className="btn-submit">
          <MdCheck color="white" size={24} />
        </button>
      </Modal.Footer>
    </Modal>
  );
});

export default DialogSetHeight;
