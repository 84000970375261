import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Modal, Pagination } from "rsuite";

import apiFoods from "../../../api/apiFoods";
import apiUser from "../../../api/apiUser";
import Item from "../../common/Item";

const DialogFavorite = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const jwt = useSelector((state) => state.jwt);
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = React.useState(1);
  const handleClose = () => setOpen(false);
  const loadData = async () => {
    try{
      // alert(JSON.stringify(jwt))
    let _items = await apiUser.loadUserFavorite(jwt);
    console.log(_items);
    setData(_items);
    }catch(e){
      
    }
  };

  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
      loadData();
    },
  }));
  return (
    <div>
      <Modal
        open={open}
        keyboard={true}
        backdrop={true}
        onClose={handleClose}
      >
        <Modal.Header className="text-center"> غذا های مورد علاقه</Modal.Header>
        <Modal.Body className="text-center mx-auto">
          {data?.map((item, index) => {
            return <Item key={index} index={index} item={item} />;
          })}
          {/* <div className="d-flex justfy-content-center align-items-center w-100"> */}
          {/* <Pagination className={"d-block text-center mx-auto"}  total={data?.all_page} limit={1} activePage={activePage} onChangePage={setActivePage} /> */}
          {/* </div> */}
        </Modal.Body>
      </Modal>
    </div>
  );
});

export default DialogFavorite;
