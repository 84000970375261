export const userReducer = (state = null, action) => {
  switch (action.type) {
    case "LOADUSER":
      return action.payload;
    case "LOGOUTUSER":
      return [...action.payload];
    case "UPDATEUSER":
      return [...action.payload];
    default:
      return state;
  }
};
