import React, {
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  forwardRef,
  useContext,
} from "react";
import { MdAdd, MdClose, MdDeleteOutline, MdEdit } from "react-icons/md";
import { Button, Whisper } from "rsuite";
import  FoodLogg  from "../../common/PopOvers";
import { v4 as uuid } from "uuid";

import apiFood from "../../../api/apiFood";
import moment from "jalali-moment";
import { useDispatch, useSelector } from "react-redux";
import { LoadUserDataAction } from "../../../redux/actions/userDataActions";
import DialogSearch from "../../dialogs/foodloggs/DialogSearch";
import UserContext from "../../../context/UserContext";
import DialogMealConfig from "../../dialogs/foodloggs/DialogMealConfig";
import storage from "../../../services/storage";
import DialogModifyUnitValue from "../../dialogs/foodloggs/DialogModifyUnitValue";

const FoodLogging = forwardRef((props, ref) => {
  const [foodLoggData, setFoodLoggData] = useState({});
  const dialogMealConfig = useRef();
  const [, forceUpdate] = useState();
  const context = useContext(UserContext);

  const [date, setDate] = useState(props.date.locale("en").format("YYYY-MM-DD"));
  const foodPopOverRef = useRef();
  const dialogSearch = useRef();
  const dialogModifyUnitValue = useRef();
  const jwt = useSelector((state) => state.jwt);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useImperativeHandle(ref, () => ({
    changeDate(date) {
      setDate(date);
      loadFoodLogging(date);
    },
  }));

  // useEffect(() => {
  //   console.log(props.date)
  //     loadFoodLogging(props.date.format("YYYY-MM-DD"));
    
  // }, []);
  useEffect(() => {
    console.log(props.date.format("YYYY-MM-DD"),66)
      loadFoodLogging(props.date.locale("en").format("YYYY-MM-DD"));
    
  }, [props.date]);

  const checkToolTip=async()=>{
    const res=await storage.checkFirstLogin(global.tooltip.foodlogg);
    if(res) foodPopOverRef.current.open();
  }
  const loadFoodLogging = (date) => {
    checkToolTip()
    console.log(date)
    apiFood.loadFoodLogging(date, jwt).then(({ response, error }) => {
      if (response) {
        // console.log(3333333333,response)
        setFoodLoggData(response);
      }
    });
  };
  const addRecipe = (item) => {
    let _meal = foodLoggData.meals[item];
    dialogSearch.current.open({ meal: _meal, date: date });
  };
  const refresh = () => {
    loadFoodLogging(date);
  };
  const deleteItem = async (food, listItems, key) => {
    // context.isLoading(true);

    apiFood
      .deleteFoodLogg(food.id, jwt)
      .then(({ response, error }) => {
        if (response) {
          let _foodLoggData = foodLoggData;
          listItems = listItems?.filter((item) => item.id != food.id) ?? [];
          _foodLoggData.meals[key].meals = listItems;
          // console.log(_foodLoggData);
          setFoodLoggData(_foodLoggData);
          refresh()
        }
      })
      .finally(() => context.isLoading(false));
  };
  const changeMeal = () => {
    dialogMealConfig.current.open(foodLoggData?.meals);
  };
  const changeUnitValue=(item)=>{
    // console.log(item,date)
    dialogModifyUnitValue.current.open(item)
  }
  return (
    <Whisper
    delayOpen={500}
    onBlur={()=>foodPopOverRef?.current?.close()}
    onClick={()=>foodPopOverRef?.current?.close()}
    onFocus={()=>foodPopOverRef?.current?.close()}
      placement="top"
      controlId={`control-id-top`}
      speaker={<FoodLogg close={()=>foodPopOverRef?.current?.close()} />}
      ref={foodPopOverRef}
      trigger="none"
    >
      <div className="food-logging mb-5">
        <DialogSearch ref={dialogSearch} onSuccess={refresh} />
        <DialogModifyUnitValue ref={dialogModifyUnitValue} onSuccess={refresh} />
        <DialogMealConfig ref={dialogMealConfig} onSuccess={refresh} />
        {Object.keys(foodLoggData?.meals ?? []).map((item, index) => {
          return (
            <div key={index} className="card">
              <div className="card-body d-flex justify-content-between align-items-center pt-0 pb-0">
                <span className="float-end">
                  {moment(foodLoggData?.meals[item].from, "HH:mm:ss").format(
                    "H:mm"
                  )}{" "}
                  تا{" "}
                  {moment(foodLoggData?.meals[item].to, "HH:mm:ss").format(
                    "H:mm"
                  )}
                </span>
                <Button
                  className="float-start"
                  style={{ backgroundColor: "white" }}
                >
                  <h3 role="button" onClick={changeMeal}>
                    {" "}
                    ...
                  </h3>
                </Button>
              </div>
              <div className="card-body d-flex justify-content-between align-items-center pt-0 ">
                <h5 className="float-end">{item}</h5>
                <Button onClick={() => addRecipe(item)} className="float-start">
                  <MdAdd size={24} />
                </Button>
              </div>
              {foodLoggData.meals[item].meals.map((meal, index) => {
                return (
                  <div
                    key={index}
                   
                    className="card-body position-relative d-flex justify-content-between align-items-center p-1 mb-1 meal me-3 ms-3"
                  >
                    <span style={{ fontSize: "0.88em",padding:2 }}>
                      {meal.item} ({meal.amount} {meal.unit}){" "}
                    </span>
                   <span>
                    <span style={{ fontSize: "0.88em" }} >{Math.round(Number(meal.calories))} kcl</span>
                    <span
                      role="button"
                      onClick={() =>
                        deleteItem(meal, foodLoggData?.meals[item]?.meals, item)
                      }
                      className=""
                    >
                      <MdDeleteOutline size={20} color={"rgba(0,0,255,0.8)"}  />
                    </span>
                    <span
                      role="button"
                      onClick={()=>{changeUnitValue(meal)}}
                      className=""
                    >
                      <MdEdit size={20} color={"rgba(0,0,255,0.8)"}  />
                    </span>
                    </span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </Whisper>
  );
});

export default FoodLogging;
