import React, { forwardRef, useImperativeHandle, useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import { Button, InputNumber, Modal } from "rsuite";
import moment from "jalali-moment";
import { useSelector } from "react-redux";
import { MdCheck } from "react-icons/md";

const DialogPickDate = forwardRef((props, ref) => {
  const [day, setDay] = useState(1);
  const [month, setMonth] = useState(1);
  const [year, setYear] = useState(1369);
  const [open, setOpen] = useState(false);
  const [checkDisabled, setCheckDisabled] = useState(true);
  const [gender, setGender] = useState("M");
  const jwt = useSelector((state) => state.jwt);
  const [selectedDay, setSelectedDay] = useState(null);
  useImperativeHandle(ref, () => ({
    open(birthday, gender) {
      birthday = birthday ?? "1990-2-1";
      birthday=moment(birthday, "YYYY/M/D").format("jYYYY-jMM-jDD");
      console.log(moment(birthday, "YYYY/M/D").format("jYYYY-jMM-jDD"),555)
      setYear(birthday.split("-")[0])
      setMonth(birthday.split("-")[1])
      setDay(birthday.split("-")[2])
      setOpen(true);
      setGender(gender);
      
    },
  }));

  const onSubmit = () => {
    setCheckDisabled(false);
    let _birthday = `${year}/${month}/${day}`;
    let _birthdayEn = moment(_birthday, "jYYYY/jM/jD").format("YYYY-MM-DD");
    console.log(_birthdayEn);
    props.onSelect({ birth_date: _birthdayEn });

    setOpen(false);
    // setBirthday();
  };
  return (
    <Modal
      className="dialog__birthday"
      open={open}
      backdrop={true}
      onClose={() => setOpen(false)}
    >
      <Modal.Header className="fw-bold">انتخاب تاریخ تولد</Modal.Header>
      <Modal.Body className="text-center ">
        {/* <Calendar
          value={selectedDay}
          maximumDate={maximumDate}
          onChange={changeHandle}
          shouldHighlightWeekends
          locale="fa"
        /> */}
        <div className="d-flex justify-content-around">
          <h6>سال</h6>
          <h6>ماه</h6>
          <h6>روز</h6>
        </div>
        <div className="d-f-j-a">
          <InputNumber value={year} onChange={setYear} max={1386} min={1330} />
          <InputNumber value={month} max={12} onChange={setMonth} min={1} />
          <InputNumber value={day} max={31} onChange={setDay} min={1} />
        </div>
      </Modal.Body>
      <Modal.Footer className="text-center">
        <Button
          className="w-50"
          style={{backgroundColor:"#a8a8a8"}}
          onClick={onSubmit}
        >
          <MdCheck color="white " size={24} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default DialogPickDate;
