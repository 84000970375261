import Global from "../config.json";
import httpService from "../services/httpService";
const apiMeals = {
    async changeMeals(meals,jwt) {
        let res = { response: null, error: null };

        await httpService
          .put( `${Global.HealyMealy}foodlogging/user-meal/`,
          meals,
        {
          headers: jwt,
        }
          )
         
          .then((json) => {
            console.log(json,666);
            switch (json.status) {
    
              case 200:
              case 201:
                res.response= 200;
                break
          
            }
    
          })
          .catch((error) => {
            if(error?.response)
            switch (error.response.status) {
              case 400:
              case 401:
                res.error =error.response.data;
                break
            }
          });
        return res;
      },
}
export default apiMeals;