import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { Loader, Modal } from "rsuite";
import apiFood from "../../../api/apiFood";
import DialogUnitValue from "./DialogUnitValue";

const DialogAmount = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [units, setUnits] = useState([]);
  const [data, setData] = useState({});
  const [foodTitle, setFoodTitle] = useState("");
  const dialogUnitValue = useRef();

  const loadAmounts = async (data) => {
    apiFood.loadUnits(data).then(({ response, error }) => {
      if (response) setUnits(response);
    });
  };
  useImperativeHandle(ref, () => ({
    open(data) {
      setOpen(true);
      setData(data);
      console.log(data);
      loadAmounts(data);
    },
  }));
  const handleClose = () => setOpen(false);
  const showUnitValue = (unit) => {
    let _data = { ...data, unit: unit };
    console.log(_data);
    dialogUnitValue.current.open(_data);
  };
  return (
    <div className="modal-container">
      <Modal backdrop={true} keyboard={true} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title className="text-center">
            مقیاس برای {data?.item?.name}{" "}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <ul className="list-group list-group-flush ">
              {units.map((item, index) => {
                return (
                  <li
                    role="button"
                    key={index}
                    onClick={() => showUnitValue(item)}
                    className="list-group-item"
                  >
                    {item.name}
                  </li>
                );
              })}
            </ul>
          </div>
        </Modal.Body>
      </Modal>
      <DialogUnitValue
        onSuccess={() => {
          props.onSuccess();
          setOpen(false);
        }}
        ref={dialogUnitValue}
      />
    </div>
  );
});

export default DialogAmount;
