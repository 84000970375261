import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Checkbox, CheckboxGroup, Input, Modal, Radio, RadioGroup } from "rsuite";
import apiJob from "../../../api/apiJob";
import UserContext from "../../../context/UserContext";
import DialogQuestion from "./DialogQuestion";

const DialogSetActivity = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const dialogQuestion=useRef();
  const [data, setData] = useState([]);
  const context=useContext(UserContext)
  const jwt=useSelector(state=>state.jwt)
  const [selectedJob, setSelectedJob] = useState();
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [skip, setSkip] = useState(false)
  const [query, setQuery] = useState([]);
  const loadJobs = () => {
    apiJob.loadJobs().then(({ response, error }) => {
      if (response) {
        setData(response);
        setQuery(response);
      }
    });
    apiJob.loadUserJob(jwt).then(({ response, error }) => {
        if (response) {
            setSelectedJob(response.job?.map(n=>  n.id));
      
        }
      });
  };
  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
      loadJobs();
    },
  }));
  function findSpecJob(jobs) {
    for (var i = 0; i < data.length; i++) {
    // console.log(data[i].name)

      if(data[i].name=="خانه دار" || data[i].name=="بیکار" || data[i].name=="دانشجو")
        if(jobs.find(job=>job==data[i].id))
          setSkip(true)
    }
}
  const handleChange = (value,e) => {

    value=value.filter(n => n)
    setSelectedJob(value   )
    findSpecJob(value)
    

      apiJob.setJob(value,jwt).then(({response,error})=>{
          if(response){
              context.notify("شغل شما با موفقیت ثبت شد","success")

          }
      })
  };
  const handleSearch = (value) => {
    if(value==="")
    return  setQuery(data)
    let filterJobs = query.filter(job => {
        return job.name.indexOf(value) > -1
    })
    setQuery(filterJobs)
  };
  const checkActivity=()=>{
    
    // setOpen(false)
    dialogQuestion.current.open(skip)
  }
  return (
    <Modal open={open} onClose={()=>{
        props.onSelect()
        setOpen(false)
    }}>
      <DialogQuestion onSuccess={()=>setOpen(false)} ref={dialogQuestion}/>
      <Modal.Header>فعالیت بدنی مرتبط با شغل</Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="jobs">شغل خود را از لیست انتخاب کنید:</label>
          <Input placeholder="مثال: کارمند" name="jobs" onChange={handleSearch} />
          <CheckboxGroup name="jobList" value={selectedJob} onChange={handleChange}>
            {query.map((item, index) => {
              return (
                <Checkbox key={index} value={item.id} >
                  {item.name}
                </Checkbox>
              );
            })}
          </CheckboxGroup>
        </div>
      </Modal.Body>
      <Modal.Footer>
      <Button   appearance="default"   onClick={checkActivity}
                  className="float-start"
                >
                  مرحله بعد
                </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default DialogSetActivity;
