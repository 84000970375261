import React, { useState } from "react";
import { MdCircle, MdHome, MdPerson, MdSearch } from "react-icons/md";
import { NavLink } from "react-router-dom";

const NavbarBottom = () => {
  const [activePage, setActivePage] = useState("home");
  return (
    <nav className="navbar navbar-expand-lg navbar-light nav-menu position-fixed w-100">
      <div className="container-fluid d-flex justify-content-betweenu">

        <NavLink
          to="/"
          className={({ isActive }) => {
            if (!isActive) return "inactive";
            setActivePage("home");
            return "active";
          }}
        >
          <div className="d-flex flex-column align-items-center">
            <MdHome size={24} />
            {activePage == "home" && <MdCircle size={8} />}
          </div>
        </NavLink>
        <NavLink
          to="/search"
          className={({ isActive }) => {
            if (!isActive) return "inactive";
            setActivePage("search");
            return "active";
          }}
        >
          <div className="d-flex flex-column align-items-center">
            <MdSearch size={24} />
            {activePage == "search" && <MdCircle size={8} />}
          </div>
        </NavLink>
        <NavLink
          to="/menu"
          className={({ isActive }) => {
            if (!isActive) return "inactive";
            setActivePage("menu");
            return "active";
          }}
        >
          <div className="d-flex flex-column align-items-center">
            <MdPerson size={24} />
            {activePage == "menu" && <MdCircle size={8} />}
          </div>
        </NavLink>
      </div>
    </nav>
  );
};

export default NavbarBottom;
