import React, { useContext, useEffect, useRef, useState } from "react";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { BsArrowClockwise } from "react-icons/bs";
import moment from "jalali-moment";
import Carousel from 'react-elastic-carousel'

import UserContext from "../../../context/UserContext";
import apiUser from "../../../api/apiUser";
import { useDispatch, useSelector } from "react-redux";
import { LoadUserDataAction } from "../../../redux/actions/userDataActions";
import { Button } from "rsuite";

const SelectDate = (props) => {
  const context = useContext(UserContext);
  const [days, setDays] = useState([]);
  const jwt=useSelector(state=>state.jwt)
  const [scrollX, setScrollX] = useState(0)
  const dispatch=useDispatch()
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(moment());
  const [diff, setDiff] = useState(0);
  const [showToday, setShowToday] = useState(false);
  const daysBox = useRef();

  const nextDay = () => {
  let _date= date.clone().add(1, 'days');
  setDate(_date)
  props.onSelectDate(_date)
  daysBox.current.slidePrev()

  };
  const beforeDay = () => {
    let _date=date.clone().subtract(1, "days")
    setDate(_date)
    props.onSelectDate(_date)
    daysBox.current.slideNext()


  };
  
  const getDaysArrayByMonth = () => {
    // var daysInMonth = moment().locale("fa").daysInMonth();
    let _today=moment().locale("fa")
    var arrDays = [];
    let _amount=15
    // while (daysInMonth) {
    //   var current = moment().locale("fa").date(daysInMonth);
    //   arrDays.push(current);
    //   daysInMonth--;
    // }
  while (_amount) {
      var current = moment().locale("fa").add(_amount,"days");
      arrDays.push(current);
      _amount--;
    }
    while (_amount>-16) {
      var current = moment().locale("fa").add(_amount,"days");
      arrDays.push(current);
      _amount--;
    }
    return arrDays;
  };
  useEffect(() => {
    
    setDays(getDaysArrayByMonth());
    // setScrollX((30 - 16 - 1) * 81)
    // daysBox.current.scrollTo( (30 - 16 - 1) * 81,0);

    

  },[]);
  useEffect(() => {
    
    if (moment().locale("fa").format("jDD") !=  date.locale("fa").format("jDD") )
      setShowToday(true)
    else
    setShowToday(false)
  },[date]);
  const selectDay = (date=moment()) => {
    setDate(date);
    let _date=date.locale("en").format("YYYY-MM-DD");
    dispatch(LoadUserDataAction(_date,jwt))
    props.onSelectDate(date)
  };
  const checkDay = (day) => {
    if (moment().locale("fa").format("jDD") == day.format("jDD") || date.format("jDD") == day.format("jDD") )
      return "fw-bold bg-white";
    else if (moment().locale("fa").diff(day, "days") > 0) return "opacity-50";

    return "";
  };
  const setToday=()=>{
    setDate(moment())
    setDiff(0);
    
    props.onSelectDate(moment())
    daysBox.current.goTo(Number(12))
  }
  return (
    <div className="select-date mb-2">
      <div className="w-100 text-center m-auto p-2 d-block position-relative">
      {showToday && <Button className="fw-bold"  appearance="link" color={"blue"} onClick={setToday} style={{ right:10,top:1,backgroundColor: "white",position:"absolute" }}>
         امروز
        </Button>}
        <button onClick={beforeDay} style={{ backgroundColor: "white" }}>
          <MdArrowForwardIos color="#EC772D" size={24} />
        </button>
        <span className="fw-bold">
          {date.locale("fa").format("dddd, jD jMMMM  jYYYY")}
        </span>
        <button onClick={nextDay} style={{ backgroundColor: "white" }}>
          <MdArrowBackIos color="#EC772D" size={24} />
        </button>
       
      </div>
      <Carousel itemsToShow={7} className="days"  ref={daysBox} initialActiveIndex={12} showArrows={false}
 pagination={false}>
 
        {days.map((day,index) => {
          return (
            <span key={index} className={`day ${checkDay(day.locale("fa"))}`} onClick={()=>selectDay(day)}>
              {day.locale("fa").format("jDD")}
              <br />
              {day.format("jDD")===moment().format("jDD")?"امروز":day.format("dddd")}
            </span>
          );
        })}
    </Carousel>

      
     
    </div>
  );
};

export default SelectDate;
