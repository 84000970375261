import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "rsuite";
import "video-react/dist/video-react.css"; // import css
import {
  Player,
  LoadingSpinner,
  ForwardControl,
  ControlBar,
  BigPlayButton,
  VolumeMenuButton,
} from "video-react";
import ListRecipeHorizontal from "../../Search/components/ListRecipeHorizontal";

const DialogVideo = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
    },
  }));
  return (
    <Modal className="no-scroll" open={open} onClose={() => setOpen(false)}>
      <Modal.Header>طرز تهیه {props.food?.name}</Modal.Header>
      <Modal.Body>
        <div>
          <div style={{ direction: "ltr" }}>
            <Player
              playsInline
              // width={"100%"}
              poster="/assets/poster.png"
              src={props.food?.full_clip?.media}
            >
              <BigPlayButton position="center" />
              <LoadingSpinner />
              <ControlBar autoHide={true}>
                <ForwardControl seconds={5} order={3.1} />
                <ForwardControl seconds={10} order={3.2} />
                {/* <ForwardControl seconds={30} order={3.3} /> */}
                {/* <VolumeMenuButton /> */}
                <VolumeMenuButton vertical />
              </ControlBar>
            </Player>
          </div>
          <div>
            <ListRecipeHorizontal data={props.relatedFoods ?? []} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default DialogVideo;
