import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { div, Divider, Grid, Modal, Row } from "rsuite";
import apiUser from "../../../api/apiUser";
import UserContext from "../../../context/UserContext";
import Chart from "../../common/Chart";

const DialogBmi = forwardRef((props, ref) => {
  const context=useContext(UserContext)
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const jwt = useSelector((state) => state.jwt);

  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
      apiUser.getWeightChart(jwt).then(({ response, error }) => {
        if (response) {
          setData(response);
        }
        else{
          context.notify(error?.detail??"خطا","error")
        }
      });
    },
  }));
  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)} backdrop keyboard>
        <Modal.Header className="text-center fw-bold">محاسبه توده بدنی و اضافی بدن</Modal.Header>
        <Modal.Body>
          <div>
            <div className="d-flex justify-content-center align-items-center  ">
              <div
                className="text-center  pb-2 position-relative"
                style={{ borderBottom: "solid #1a9cb5 7px", width: "20%" }}
              >
                <img src="/images/icons/1.svg" width={64} height={64} />
                {data?.level == 1 && <div className="active-body"></div>}
              </div>
              <div
                className="text-center  pb-2  position-relative"
                style={{ borderBottom: "solid #00cda8 7px", width: "20%" }}
              >
                <img src="/images/icons/2.svg" width={64} height={64} />
                {data?.level == 2 && <div className="active-body"></div>}
              </div>
              <div
                className="text-center  pb-2  position-relative"
                style={{ borderBottom: "solid #ffd100 7px", width: "20%" }}
              >
                <img src="/images/icons/3.svg" width={64} height={64} />
                {data?.level == 3 && <div className="active-body"></div>}
              </div>
              <div
                className="text-center  pb-2  position-relative"
                style={{ borderBottom: "solid #fe3d33 7px", width: "20%" }}
              >
                <img src="/images/icons/4.svg" width={64} height={64} />
                {data?.level == 4 && <div className="active-body"></div>}
              </div>
              <div
                className="text-center pb-2  position-relative"
                style={{ borderBottom: "solid #c90101 7px", width: "20%" }}
              >
                <img src="/images/icons/5.svg" width={64} height={64} />
                {data?.level == 5 && <div className="active-body"></div>}
              </div>
            </div>
            <div className="p-4 text-center title-body mb-3">
                <p className="fw-bold">{data?.title}</p>
            </div>
            <div>
              <Chart charts={data??[]} />
            </div>
            <div dir="trl" className="d-flex justify-content-between align-items-center dialog-information m-0 mt-5 pt-3">
              <span className="">وزن ایده آل :{data?.ideal_weight}  kg</span>
              <Divider vertical />
              <span className="">وزن مطلوب  :{data?.optimal_weight}  kg</span>
            </div>
            <div  dir="rtl" className="d-flex justify-content-between align-items-center dialog-information m-0">
              <span className="d-block text-right" dir="ltr">  {data?.bmi} :BMI  </span>
              <Divider vertical />
              <span className="">اضافه وزن : {data?.over_weight}</span>
              <Divider vertical />
              <span className="">چربی :{data?.fat_percentage}%</span>
              </div>
              <div className="d-flex justify-content-center align-items-center dialog-information">

              <span className="d-block">انرژی مورد نیاز روزانه : {data?.daily_calories} kcl</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
});

export default DialogBmi;
