import React, {
  useImperativeHandle,
  useState,
  useRef,
  forwardRef,
} from "react";
import { Loader, Modal } from "rsuite";
import moment from "jalali-moment"
import { useSelector } from "react-redux";
import apiFood from "../../../api/apiFood";
import DialogAmount from "./DialogAmount";

const DialogSearch = forwardRef((props, ref) => {
  const jwt = useSelector((state) => state.jwt);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [searchTimer, setSearchTimer] = useState();
  const [recList, setRecList] = useState([]);
  const [recAllList, setRecAllList] = useState([]);
  const [matList, setMatList] = useState([]);
  const [matAllList, setMatAllList] = useState([]);
  const dialogAmount = useRef();

  const requestSearchFood = async (query) => {
    // context.isLoading(true);
    await apiFood.searchFood(query, jwt).then(({ response, error, server }) => {
      setIsLoading(false);
      setRecList(response.recipe.slice(0, 6));
      setRecAllList(response.recipe);
      setMatAllList(response.material);
      setMatList(response.material.slice(0, 6));
    });
    // .finally(() => context.isLoading(false));
  };
  useImperativeHandle(ref, () => ({
    open(data) {
      setOpen(true);
      setData(data);
    },
  }));
  const handleSearch = (query) => {
    console.log(query);
    setIsLoading(true);
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    setSearchTimer(
      setTimeout(() => {
        if (query != "") requestSearchFood(query);
        else {
          setRecList([]);
          setMatList([]);
          setIsLoading(false);
        }
      }, 1000)
    );
  };
  const handleClose = () => setOpen(false);
  const showUnits = (item, type) => {
    let _data = { ...data, item: item, type: type };
    setRecList([]);
    setMatList([]);
    console.log(_data);
    dialogAmount.current.open(_data);
  };
  const showRecMore = () => {
    setRecList(recAllList.slice(0, recList.length + 6));
  };
  const showMatMore = () => {
    setMatList(matAllList.slice(0, matList.length + 6));
  };
  return (
    <div className="modal-container">
      <Modal backdrop={true} keyboard={true} open={open} onClose={handleClose}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Modal.Header>
            <Modal.Title className="text-center"> {data?.date ? moment(data.date,"YYYY-MM-DD").format("jYYYY-jMM-jDD"):""}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <div className="form-group">
                <label className="control-label">
                  جستجو میان دستورهای پخت، مواد اولیه و …
                </label>
                <div className="input-group    ">
                  <input
                    type="text"
                    className="form-control input   "
                    // value={query}
                    name="query"
                    aria-describedby="query"
                    placeholder="مثال:  پنیر"
                    onChange={(e) => {
                      handleSearch(e.target.value);
                    }}
                  />
                  {isLoading && (
                    <span className=" loader">
                      <Loader />
                    </span>
                  )}
                </div>
              </div>
              {recList.length > 0 && (
                <div className="card border-0 ps-5 m-1">
                  <p className="fw-bold">دستور پخت</p>
                  <ul className="list-group list-group-flush ">
                    {recList.map((item, index) => {
                      return (
                        <li
                          role="button"
                          key={index}
                          onClick={() => showUnits(item, "recipe")}
                          className="list-group-item direction"
                        >
                          {item.name}
                        </li>
                      );
                    })}
                    {recList.length < recAllList.length && (
                      <li
                        role="button"
                        onClick={showRecMore}
                        className="list-group-item fw-bold"
                      >
                        نمایش بیشتر
                      </li>
                    )}
                  </ul>
                </div>
              )}
              {matList.length > 0 && (
                <div className="card border-0 ps-5  m-1">
                  <p className="fw-bold">مواد اولیه</p>
                  <ul className="list-group list-group-flush ">
                    {matList.map((item, index) => {
                      return (
                        <li
                          key={index}
                          role="button"
                          onClick={() => showUnits(item, "material")}
                          className="list-group-item direction"
                        >
                          {item.name}
                        </li>
                      );
                    })}
                    {matList.length < matAllList.length && (
                      <li
                        role="button"
                        onClick={showMatMore}
                        className="list-group-item fw-bold"
                      >
                        نمایش بیشتر
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </Modal.Body>
        </form>
      </Modal>
      <DialogAmount
        onSuccess={() => {
          props.onSuccess();
          setOpen(false);
        }}
        ref={dialogAmount}
      />
    </div>
  );
});
export default DialogSearch;
