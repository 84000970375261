import React, { useEffect, useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserContext from "../../context/UserContext";
import moment from "jalali-moment";
import DialogUsername from "../dialogs/auth/DialogUsername";
import SelectDate from "./components/SelectDate";
import BMI from "./components/BMI";
import { Button, ButtonGroup, Divider, Whisper } from "rsuite";
import SearchBox from "./components/SearchBox";
import { loadUserProfileAction } from "../../redux/actions/userActions";
import FoodLogging from "./components/FoodLogging";
import {
  MdArrowBack,
  MdFoodBank,
  MdRestaurant,
  MdSettingsAccessibility,
  MdTapas,
} from "react-icons/md";
import DialogBody from "../dialogs/user/DialogBody";
import Advisor from "./components/Advisor";
import { loadJWTAction } from "../../redux/actions/jwtActions";
import Features from "./components/Features";
import Information from "./components/Information";
import {  useNavigate } from "react-router";

const Home = () => {
  const user = useSelector((state) => state.user);
  const context = useContext(UserContext);
  const [nickname, setNickname] = useState(null)
  const dialogUsername = useRef();
  const foodLogging = useRef();
  const dialogBody = useRef();
  const navigate=useNavigate()
  const [showFoodLogg, setShowFoodLogg] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.jwt);
  const init = () => {
    context?.showLogin(false);
    dispatch(loadUserProfileAction(jwt));
  };
  useEffect(() => {
    // dialogUsername.current.open()

    setNickname(user?.nickname)
  }, [user]);
  useEffect(() => {
    if (!jwt) context?.showLogin(true);
    else init();
  }, [jwt]);

  const foodLoggPanel = () => {
    setShowFoodLogg(true);
  };
  const onSelectDate = (date) => {
    // await setShowFoodLogg(true);
    setSelectedDate(date);
    // foodLogging?.current?.changeDate(date);
  };
  return (
    <div className="home mb-5 pb-5">
      <DialogUsername ref={dialogUsername} onSuccess={init} />
      {jwt  && <DialogBody ref={dialogBody} />}
      <div className="home__header mb-2 p-2 pt-0 pb-0" onClick={()=>navigate("/menu")}>
        <div>
          <img src="/images/icons/user.svg" /> {nickname}
        </div>
        <img src="/images/logo.png" className="logo" />
      </div>
      <SelectDate onSelectDate={onSelectDate} />
      {!showFoodLogg && (
        <>
          <BMI
            onOpen={() => dialogBody.current.open()}
            selectedDate={selectedDate}
          />
          <Features showFoodLogg={setShowFoodLogg} />
          {/* <SearchBox /> */}
          <Information  selectedDate={selectedDate}/>
        </>
      )}
    
      {/* {!showFoodLogg && <Advisor /> */}
      {showFoodLogg && (
        <div>
          <Divider>
            {" "}
            <span
              onClick={() => setShowFoodLogg(false)}
              className="float-start"
            >
              برگشت <MdArrowBack />
            </span>{" "}
          </Divider>

          <FoodLogging ref={foodLogging} date={selectedDate} />
        </div>
      )} 
    </div>
  );
};

export default Home;
