import apiFoods from "../../api/apiFoods";

const initialize =async (jwt) => {
  const newsFoods = await apiFoods.loadNewFoods();
  const offerFoods = await apiFoods.loadOfferFoods();
  const offerTitleFoods = await apiFoods.loadTitleOfferFoods();
  const favoriteFoods = await apiFoods.loadFavoriteFoods(jwt);
  return {
    news: {data:newsFoods,title:"جدید ترین های هیلی میلی"},
    offer: {data:offerFoods,title:offerTitleFoods},
    favorite: {data:favoriteFoods,title:"محبوب ترین های هیلی میلی"},
  };
};
export default initialize;