import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "rsuite";
import Ruler from "rc-ruler";
import "rc-ruler/dist/index.css";
import { MdCheck } from "react-icons/md";

const DialogSetWaist = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(160);
  useImperativeHandle(ref, () => ({
    open(waist) {
      setOpen(true);
      setValue(waist);
    },
  }));

  const handleDragChange = (value) => {
    setValue(value);
  };
  const handleSubmit = () => {
    props.onSelect({ waist: value });
    setOpen(false);
  };
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Modal.Header>درج دور کمر</Modal.Header>

      <Modal.Body style={{direction:"ltr",overflow: "inherit",
    padding: 10}}>
        <Ruler
          value={value}
          onDrag={handleDragChange}
          start={60}
          end={150}
          step={1}
        />
      </Modal.Body>
      <Modal.Footer>
        <p class="dialog-information">
          در محاسبه دور کمر یا دور شکم باید شکم در حالت عادی باشد و به سمت تو یا
          بیرون داده نشده باشد. برای اندازه گیری دور کمر باید متر از لبه بالایی
          دو استخوان لگن در دو طرف پهلو بگذرد و دقت شود که حتماً متر صاف و موازی
          با زمین باشد و به‌صورت کاملاً افقی از روی ناف عبور کند. در این حالت
          اندازه گیری دور شکم به درستی انجام می‌شود.
        </p>
        <button onClick={handleSubmit} className="btn-submit">
          <MdCheck color="white" size={24} />
        </button>
      </Modal.Footer>
    </Modal>
  );
});

export default DialogSetWaist;
