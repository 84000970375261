import React, { useState, useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../components/Home/Home";
import DialogLogin from "../components/dialogs/auth/DialogLogin";
import UserContext from "../context/UserContext";
import { useDispatch, useSelector } from "react-redux";
import { loadJWTAction } from "../redux/actions/jwtActions";
import Loading from "../components/common/Loading";
import Search from "../components/Search/Search";
import NavbarBottom from "../components/common/NavbarBottom";
import Food from "../components/Food/Food";
import { toaster } from "rsuite";
import message from "../components/common/Message";
import Menu from "../components/Menu/Menu";
import responsive from "../components/common/Responsive";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const HealyMealy = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [welcome, setWelcome] = useState(true);
  const [elementsSize, setElementsSize] = useState(
    responsive(getWindowDimensions())
  );

  const jwt = useSelector((state) => state.jwt);
  const dispatch = useDispatch();
  const dialogLogin = useRef();
  // const elementsSize=()=>{
  //   return responsive(getWindowDimensions())
  // }

  function handleResize() {
    setElementsSize(responsive(getWindowDimensions()));
    console.log(responsive(getWindowDimensions()));
    // elementSize=responsive(getWindowDimensions())
  }
  // useEffect(async () => {
  //   const token = await localStorage.getItem("token");
  //   console.log(token,"state update")
  //   if (token === null) showLogin(true);
  // });
  const runApp=()=>{
    setWelcome(false)
    setIsLoading(true)
    dispatch(loadJWTAction()).then(()=>{
      setTimeout(()=> setIsLoading(false),1500)
    });
  }
  useEffect(() => {
    setTimeout(()=>runApp() ,5500)

   
    

    // console.log(jwt);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const showLogin = (show) => {
    show ? dialogLogin.current.open() : dialogLogin.current.close();
  };
  const notify = (msg, type) => {
    toaster.push(message(msg, type), { placement: "topStart" });
  };
 const  fixNumbers=(str)=>
{
 const  persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g]
 const arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g]
  if(typeof str === 'string')
  {
    for(var i=0; i<10; i++)
    {
      str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
    }
  }
  return str;
};
  return (
    <UserContext.Provider
      value={{
        isLoading: setIsLoading,
        showLogin: showLogin,
        notify: notify,
        fixNumbers: fixNumbers,
        elementsSize: elementsSize,
      }}
    >
    { welcome && <div className="greeting"><img src="/images/motion.gif"/></div>}

      <DialogLogin ref={dialogLogin} />
      <Routes>
        <Route path="/search" element={<Search />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/food/:fid" element={<Food />} />
        <Route path="/" exact element={<Home />} />
      </Routes>
      <NavbarBottom />
      {isLoading  && <Loading />}

    </UserContext.Provider>
  );
};

export default HealyMealy;
