import React, { useContext, useRef } from "react";
import { MdInfo, MdShoppingBasket } from "react-icons/md";
import { useSelector } from "react-redux";
import { IconButton, List } from "rsuite";
import UserContext from "../../../context/UserContext";
import apiUserFood from "../../../api/apiUserFood";
import DialogValues from "../../dialogs/food/DialogValues";

const MaterialFood = (props) => {
  const jwt = useSelector((state) => state.jwt);
  const context = useContext(UserContext);
  const dialogValues = useRef();
  
  const addBasket = () => {
    apiUserFood.addBasket(props?.food.id, jwt).then(({ response, error }) => {
      if (error)
        context.notify("این غذا از قبل در لیست خرید شما وارد شده است", "error");
      else context.notify("این غذا در لیست خرید شما  قرار گرفت", "success");
    });
  };
  return (
    <div>
      <DialogValues
        ref={dialogValues}
        nutritive_values={props?.food?.nutritive_values}
      />
      <div className="d-flex d-flex  justify-content-between align-items-center">
        <p>مواد لازم برای {props?.food?.suitable_for} نفر</p>
        <div className="d-flex p-2 gap-2">
          <IconButton
            onClick={addBasket}
            circle
            icon={<MdShoppingBasket size={24} />}
          />
          <IconButton
            onClick={() => dialogValues.current.open()}
            circle
            icon={<MdInfo size={24} />}
          />
        </div>
      </div>
      <List bordered hover size={"lg"}>
        {props?.food?.sids?.map((item, index) => (
          <List.Item className="pb-4" key={index} index={index}>
            <span className="float-end "> {item.material}</span>
            <span className="float-start ">
              {" "}
              {item.amount} {item.unit}
            </span>
          </List.Item>
        ))}
      </List>
    </div>
  );
};

export default MaterialFood;
