import React, { forwardRef } from "react";
import { Popover } from "rsuite";
const FoodLogg = forwardRef((props, ref) => {
  return (
    <Popover  {...props}
    ref={ref}
      onBlur={() => props.close()}
      onClick={() => props.close()}
      onFocus={() => props.close()}
      title="هیلی میلی عزیز"
    >
      <p> در این قسمت وعده‌های هر روز رو با اسم و زمان</p>
      <p> می‌بینی و می‌تونی با کلیک روی + غذا یا خوراکی</p>
      <p>جدیدی رو که مصرف کردی به اون اضافه کنی تا</p>
      <p>کالری اون رو محاسبه کنه.</p>
    </Popover>
  );
});
export default FoodLogg;
