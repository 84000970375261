import React from "react";
import "rsuite/dist/rsuite-rtl.min.css";
// import "rsuite/dist/rsuite.min.css";
import {BrowserRouter ,HashRouter} from "react-router-dom"
import HealyMealy from "./HealyMealy";
const App = () => {
    return (
        <HashRouter>
            <HealyMealy />
        </HashRouter>
    );
};


export default App;

