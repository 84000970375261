import React, {
  forwardRef,
  useImperativeHandle,
  useRef,useContext,
  useState,
} from "react";
import { MdCheck } from "react-icons/md";
import { InputNumber, Modal } from "rsuite";
import apiFood from "../../../api/apiFood";
import SimpleReactValidator from "simple-react-validator";
import { useSelector } from "react-redux";
import UserContext from "../../../context/UserContext";

const DialogModifyUnitValue = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const jwt = useSelector((state) => state.jwt);
  const [, forceUpdate] = useState();
  const context = useContext(UserContext)
  const [data, setData] = useState({});
  const [value, setValue] = useState(0);
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "پر کردن این فیلد الزامی میباشد",
        min: "باید یک عدد حداقل باشد",
        numeric: "لطفا به صورت عددی یا اعشاری وارد نمایید",
        value: "پر کردن این فیلد الزامی میباشد",
      },
      element: (message) => <div style={{ color: "red" }}>{message}</div>,
    })
  );
  const handleClose = () => setOpen(false);
  useImperativeHandle(ref, () => ({
    open(data) {
      setOpen(true);
      setValue(Number(data.amount))
      setData(data);
      validator.current.purgeFields() 
    },
  }));
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validator.current.allValid() ) {
      validator.current.showMessages();
      forceUpdate(1);
      return;
    }
    console.log(data)
    let _form = {
      // meal:data.meal.id,
      // date: data.date,
      // unit: data.unit.id,
      amount: value,
      // recipe: data.type == "recipe" ? data.item.id : null,
      // material: data.type == "material" ? data.item.id : null,
    };
    console.log(value,_form)
    apiFood.editFoodLogging(data.id,_form, jwt)
    .then(({response,error})=>{
        if(response){
            props.onSuccess()
            setOpen(false)
        }else{
          context.notify("این غذا از قبل در این وعده به ثبت رسیده است","warning")
        }
    });
  };
  return (
    <div>
      <Modal backdrop={true} keyboard={true} open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title className="text-center">
              مقدار برای {data?.item}{" "}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <div className="input-box d-flex justify-content-between align-items-center">
                <label>مقدار ({data?.unit})</label>
                <InputNumber
                  value={value}
                  name="value"
                  style={{ width: "30%" }}
                  onChange={(value) => {
                    setValue(value);
                    validator.current.showMessageFor("value");
                  }}
                />
              </div>
              {validator.current.message(
                "value",
                value,
                "required|numeric|min:1,num"
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn-submit">
              <MdCheck color="white" size={24} />
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
});

export default DialogModifyUnitValue;
