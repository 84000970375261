import React from "react";
import { render } from "react-dom";
import App from "./containers/App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
global.tooltip={foodlogg:1}
global.KG = Array(120)
  .fill(30)
  .map((x, y) => {
    return { id: `${x + y}`, value: `${x + y}` };
  });
global.GR = Array(99)
  .fill(0)
  .map((x, y) => {
    return { id: `${x + y}`, value: `${x + y}` };
  });
render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
