import React, {
  forwardRef,
  useImperativeHandle,

  useState,
} from "react";
import { Modal } from "rsuite";
import Ruler from "rc-ruler";
import "rc-ruler/dist/index.css";
import { MdCheck } from "react-icons/md";

const DialogSetWrist = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(160);
  useImperativeHandle(ref, () => ({
    open(wrist) {
      setOpen(true);
      setValue(wrist);
    },
  }));
 
  const handleDragChange = (value) => {
    setValue(value);
  };
  const handleSubmit = () => {
      
     props.onSelect({wrist:value})
     setOpen(false)
  };
  return (
    <Modal  open={open} onClose={() => setOpen(false)}>
            <Modal.Header>درج دور مچ</Modal.Header>

      <Modal.Body style={{direction:"ltr",overflow: "inherit",
    padding: 10}}>
        <Ruler
          value={value}
          onDrag={handleDragChange}
          start={10}
          end={50}
          step={1}
        />
      </Modal.Body>
      <Modal.Footer>
      <p class="dialog-information">
        برای اندازه‌گیری مچ دست دو راه وجود دارد:<br/>
                یک نخ را به صورتی که کاملا کیپ دستتان باشد، دور مچ بپیچید و علامت بزنید. نخ را از دور مچ باز کنید و کنار یک خط کش قرار بدهید.<br/>
                یا متر را دقیقا از روی برآمدگی استخوان مچ دست رد کنید. متر نه خیلی تنگ و نه خیلی آزاد باشد. عدد روی آن همان اندازه‌ی دور مچ شماست.              <br/>
                                  </p>
        
        <button onClick={handleSubmit} className="btn-submit">
          <MdCheck color="white" size={24} />
        </button>
      </Modal.Footer>
    </Modal>
  );
});

export default DialogSetWrist;
