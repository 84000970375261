import Global from "../config.json";
import httpService from "../services/httpService";
const apiUserFood = {
  async addBasket(fid, jwt) {
    let res = { response: null, error: null };
    let resStatus = 0;
    await httpService
      .post(
        `${Global.HealyMealy}recipe/${fid}/shopping`,
        {},
        {
          headers: jwt,
        }
      )

      .then((json) => {
        console.log(json, resStatus);
        switch (json.status) {
          case 200:
          case 201:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 400:
          case 401:
            res.error = 400;
            break;
        }
      });

    return res;
  },
  async loadRateItems( jwt) {
    let res = { response: null, error: null };
    let resStatus = 0;
    await httpService
      .get(
        `${Global.HealyMealy}recipe/rate-item/`,
        {
          headers: jwt,
        }
      )

      .then((json) => {
        console.log(json, resStatus);
        switch (json.status) {
          case 200:
          case 201:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 400:
          case 401:
            res.error = 400;
            break;
        }
      });

    return res;
  },
  async loadUserRateItems(fid, jwt) {
    let res = { response: null, error: null };
    let resStatus = 0;
    await httpService
      .get(
        `${Global.HealyMealy}recipe/${fid}/rate/`,
        {
          headers: jwt,
        }
      )

      .then((json) => {
        console.log(json, "rate user");
        switch (json.status) {
          case 200:
          case 201:
            res.response = json.data;
            break;
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 400:
          case 401:
            res.error = 400;
            break;
        }
      });

    return res;
  },
  async foodLike(fid, jwt) {
    let res = { response: null, error: null };
    let resStatus = 0;
    await httpService
      .post(
        `${Global.HealyMealy}recipe/${fid}/like/`,
        {},
        {
          headers: jwt,
        }
      )

      .then((json) => {
        console.log(json, resStatus);
        switch (json.status) {
          case 200:
          case 201:
            res.response =200;
            break;
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 400:
          case 401:
            res.error = 400;
            break;
        }
      });

    return res;
  },
};
export default apiUserFood;
