import React from 'react'
import { useNavigate } from 'react-router'

const Features = (props) => {
const navigate=useNavigate();
const showFoodLogg=()=>{
    props.showFoodLogg(true)
}
    return (
    <div className='features'>
        {/* <div className='item '>
            <img src="/images/icons/addfood.svg" alt="" />
            <h6>ثبت وعده غذا</h6>
        </div> */}
      
        <div className=' item ' onClick={showFoodLogg}>
            <img src="/images/icons/planing.svg" alt="" />
            <h6>برنامه ریزی و ثبت وعده</h6>
        </div>
        
        <div className=' item ' onClick={()=>navigate("/search")} >
            <img src="/images/icons/order.svg" alt="" />
            <h6>دستور پخت</h6>
        </div>
        <div className=' item'>
            <img src="/images/icons/diet.svg" alt="" />
            <h6>دریافت رژیم</h6>
        </div>
    </div>
  )
}

export default Features