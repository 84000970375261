import httpService from "../services/httpService";
import Global from "../config.json";
const apiSearch={
    async loadCategoreis() {
        let res = { response: null, error: null };
        await httpService
          .get(`${Global.HealyMealy}recipe/tag/?is_selected=true`)
          .then((json) => {
            console.log(json);
            switch (json.status) {
              case 201:
              case 200:
                res.response = json.data;
                break;
            }
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
              case 401:
              case 404:
                res.error =error.response.data;
                break;
            }
          });
         
        return res;
      },

}
export default apiSearch;