import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { MdCheck } from "react-icons/md";
import { InputNumber, Modal } from "rsuite";
import apiFood from "../../../api/apiFood";
import SimpleReactValidator from "simple-react-validator";
import { useSelector } from "react-redux";
import UserContext from "../../../context/UserContext";

const DialogUnitValue = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const jwt = useSelector((state) => state.jwt);
  const [, forceUpdate] = useState();
 const context=useContext(UserContext)
  const [data, setData] = useState({});
  const [value, setValue] = useState(0);
  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        required: "پر کردن این فیلد الزامی میباشد",
        min: "باید یک عدد حداقل باشد",
        numeric: "لطفا به صورت عددی یا اعشاری وارد نمایید",
        value: "پر کردن این فیلد الزامی میباشد",
      },
      element: (message) => <div style={{ color: "red" }}>{message}</div>,
    })
  );
  const handleClose = () => setOpen(false);
  useImperativeHandle(ref, () => ({
    open(data) {
      setOpen(true);
      setData(data);
    },
  }));
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validator.current.allValid()) {
      validator.current.showMessages();
      forceUpdate(1);
      return;
    }
    console.log(data)
    let _form = {
      meal:data.meal.id,
      date: data.date,
      unit: data.unit.id,
      amount: value,
      recipe: data.type == "recipe" ? data.item.id : null,
      material: data.type == "material" ? data.item.id : null,
    };
    apiFood.insertFoodLogging(_form, jwt)
    .then(({response,error})=>{
        if(response){
            props.onSuccess()
            setOpen(false)
            setValue(0)
        }else{
            context.notify("این غذا از قبل در این وعده به ثبت رسیده است","warning")
        }
    });
  };
  return (
    <div>
      <Modal backdrop={true} keyboard={true} open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title className="text-center">
              مقدار برای {data?.item?.name}{" "}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <div className="input-box d-flex justify-content-between align-items-center">
                <label>مقدار ({data?.unit?.name})</label>
                <input
                type={"text"}
                  value={value}
                  name="value"
                  min={0}
                  pattern={"[+-]?([0-9]*[.])?[0-9]+"}
                  step={1}
                  style={{ width: "30%" }}
                  onChange={(e) => {
                   // setValue(value);
                    setValue(context.fixNumbers(e.target.value));
                   validator.current.showMessageFor("value");
            
                 }}
                />
              </div>
              {validator.current.message(
                "value",
                value,
                "required|min:1|numeric"
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn-submit">
              <MdCheck color="white" size={24} />
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
});

export default DialogUnitValue;
