import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import apiFoods from "../../api/apiFoods";
import UserContext from "../../context/UserContext";
import { loadUserProfileAction } from "../../redux/actions/userActions";
import Categories from "./components/Categories";
import ListRecipeHorizontal from "./components/ListRecipeHorizontal";
import SearchBox from "./components/SearchBox";
import initialize from "./init";

const Search = () => {
  const context = useContext(UserContext);
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.jwt);
  const [data, setData] = useState(null);
  const init = async () => {
    context?.showLogin(false);
    dispatch(loadUserProfileAction(jwt));
    let _data = await initialize(jwt);
    setData(_data);
  };

  useEffect(() => {
    if (!jwt) context?.showLogin(true);
    else init();
  }, [jwt]);
  return (
    <div className="search m-2 p-2  mb-5 pb-5">
      <div className="float-start mb-3">
        <img src="/images/logo.png" className="logo " />
      </div>
      <SearchBox />
  
      <Categories />
  
        <Fragment>
          <ListRecipeHorizontal data={data?.favorite ?? []} />
          <ListRecipeHorizontal data={data?.news ?? []} />
          <ListRecipeHorizontal data={data?.offer ?? []} />
        </Fragment>
    
    </div>
  );
};

export default Search;
