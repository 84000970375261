import { MdStar } from "react-icons/md";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

const Item = (props) => {
  const navigate = useNavigate();
  const H = 146;
  const W = 226;
  const handleItem = () => {
    if (props?.onTop) props.onTop.scrollIntoView({ behavior: "smooth" });
    navigate("/food/" + props.item.id);
  };
  return (
    // <Link to={"/food/"+props.item.id}>
    <div
      onClick={handleItem}
      role={"button"}
      key={props.index}
      className="text-center position-relative  m-auto "
      style={{ width: W + 10 }}
    >
      <img
        style={{ width: props.W ?? W, height: props.H ?? H }}
        src={props.item.cover}
        className="item-carousel"
      />
      <p className="position-absolute item-text">{props.item.name}</p>
      <span className="position-absolute align-items-center item-rate">
        {props.item.rate}
        <MdStar color={"yellow"} />
      </span>
    </div>
    // </Link>
  );
};
export default Item;
