import Global from "../config.json";
import httpService from "./httpService";
const storage = {
  async saveJWT(token) {
    try {
      token.date = Date.now() / 1000;
      console.log("save token: " + token);
      await localStorage.setItem("token", JSON.stringify(token));
      return {
        Authorization: token.token_type + " " + token.access_token,
      };
    } catch (error) {
      console.log("localStorage Error: " + error.message);
    }
  },
  async checkFirstLogin(step) {
    let steps = await localStorage.getItem("steps");
    if (steps === null) {
      steps = [0, 0, 0];
      steps[step - 1] = step;
      await localStorage.setItem("steps", JSON.stringify(steps));
      return true;
    }

    steps = JSON.parse(steps);
    if (steps[step - 1] == 0) {
      steps[step - 1] = step;
      await localStorage.setItem("steps", JSON.stringify(steps));
      return true;
    }
    return false;
  },
  async deleteJWT() {
    try {
      await localStorage.removeItem("token");
      return "";
    } catch (error) {
      console.log("AsyncStorage Error: " + error.message);
    }
  },
  isTokenExpired(token) {
    console.log("expire time",
      token.expires_in,
      Date.now() / 1000,
      token.date,
      Date.now() / 1000 - token.date
    );
    if (
      token.expires_in <=
      Math.round(Date.now() / 1000) - Number(token.date)
    ) {
      return true;
    } else {
      return false;
    }
  },
  async getAccessUsingRefresh(refreshToken) {
    try {
      return httpService
        .post(
          `${Global.HealyMealy}user/refresh-token/`,
          JSON.stringify({ refresh_token: refreshToken })
        )
        .then((res) => res.data);
    } catch (error) {
      console.log("getAccessUsingRefresh Error: " + error.message);
      return null;
    }
  },
  async loadJWT() {
    try {
      let jwt = "";
      let token = await localStorage.getItem("token");

      token = JSON.parse(token);
      console.log("load token from storage", token);

      if (token === null) return jwt;
      if (this.isTokenExpired(token)) {
        console.log("expire token");
        token = await this.getAccessUsingRefresh(token.refresh_token);
        console.log("refresh token",token);
        if (token?.expires_in) this.saveJWT(token);
        else {
          console.error(token, "خراب است");
          token= null;
        }
      }
      if (token !== null) {
        this.checkFirstLogin();
        jwt = {
          Authorization: token.token_type + " " + token.access_token,
        }; /* SMDKm9Kt8Rnd6vytYsMoSuF3V07Zjx  */
      }
      console.log("load token", jwt);

      return jwt;
    } catch (error) {
      console.log("AsyncStorage Error: " + error.message);
      return "";
    }
  },
  // async testToken(jwt){
  
  //     console.log("test", jwt);
  //    return await httpService
  //       .get(`${Global.HealyMealy}user/profile/`, {
  //         headers: jwt,
  //       })
  
  //       .then((json) => {
  //         console.log(json);
        
  //          return true
          
  //       })
  //       .catch((error) => {
  //         return false
  //       });

    
  // }
};
export default storage;
