import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
} from "react";
import { Loader, Modal } from "rsuite";
import SimpleReactValidator from "simple-react-validator";
import { MdCheck } from "react-icons/md";
import apiUser from "../../../api/apiUser";
import { useSelector } from "react-redux";
import UserContext from "../../../context/UserContext";
const DialogUsername = forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState("")
  const [showDialog, setShowDialog] = React.useState(false);
  const context = useContext(UserContext);
  const jwt = useSelector((state) => state.jwt);
  const [, forceUpdate] = React.useState();
  const [nickname, setNickname] = React.useState("");
  const [token, setToken] = React.useState("");
  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        required: "پر کردن این فیلد الزامی میباشد",
        max: "حداکثر کارکتر 50 حرف می باشد",
        min: "حداقل کارکتر 3 حرف می باشد",
        nickname: " کد نوشته شده صحیح نمی باشد",
      },
      element: (message) => <div style={{ color: "red" }}>{message}</div>,
    })
  );
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (validator.current.allValid()) {
        setIsLoading(true);

        apiUser
          .setUsername(nickname, jwt)
          .then(({ response, error }) => {
            if (response) {
              setShowDialog(false);
              props.onSuccess();
            } else {
              setError(error.detail);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        validator.current.showMessages();
        forceUpdate(1);
      }
    } catch (ex) {
      context.notify("متاسفانه مشکلی پیش آمده\nلطفا بعدا سعی کنید", "error");
      console.log(ex);
    }
  };

  const close = () =>{
     setShowDialog(false);
    setError("")
  
  }
  useImperativeHandle(ref, () => ({
    open() {
      setShowDialog(true);
    },
  }));

  return (
    <div className="modal-container">
      <Modal
        backdrop={"static"}
        keyboard={false}
        open={showDialog}
        onClose={close}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton={false}>
            <Modal.Title>انتخاب اسم</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group">
              <label className="fw-bold mb-2"> اسم خودتو وارد کن</label>
              <input
                type="text"
                className="form-control input  mt-2 mb-4 p-2"
                value={nickname}
                name="nickname"
                aria-describedby="nickname"
                placeholder="مثال:  امیرعلی"
                onChange={(e) => {
                  setNickname(e.target.value);
                  validator.current.showMessageFor("nickname");
                }}
              />
              {validator.current.message(
                "nickname",
                nickname,
                "required|max:50|min:3"
              )}
              <div className="text-danger">{error}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn-submit  mb-3"
            >
              <MdCheck color="white" size={24} />
              {isLoading && <Loader />}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
});
export default DialogUsername;
