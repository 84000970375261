import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { MdCheck } from "react-icons/md";
import { useSelector } from "react-redux";
import { List, Modal, Rate } from "rsuite";
import apiComments from "../../../api/apiComments";
import apiUserFood from "../../../api/apiUserFood";
import { v4 as uuid } from "uuid";

const DialogRate = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [, forceUpdate] = useState(0);
  const jwt = useSelector((state) => state.jwt);
  const [userRateItem, setUserRateItem] = useState([]);
  const [rateItem, setRateItem] = useState([]);
  const rateColor = ["yellow", "orange", "cyan", "violet"];
  const loadUserRateItem = async () => {
    await apiUserFood
      .loadUserRateItems(props.food.id, jwt)
      .then(({ response, error }) => {
        console.log(response, "data");
        if (response) setUserRateItem(response);
      });

    apiUserFood.loadRateItems(jwt).then(({ response, error }) => {
      if (response) setRateItem(response);
    });
  };
  useEffect(() => {
    setUserRateItem(userRateItem);
    console.log(userRateItem, "change");
  }, [userRateItem]);
  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
      loadUserRateItem();
    },
  }));
  const submitHandle = () => {
    let data = [];

    userRateItem.map((item, index) => {

      if (item?.rate)
        data.push({
          rate_item: item.rate_item.id,
          rate: item.rate,
        });
    });
    console.log(data);
    apiComments
      .insertRate(props.food.id, data, jwt)
      .then(({ response, error }) => {
        if (response) {
          props.onSuccess();
          setOpen(false);
        } else {
        }
      });
  };
  const checkRate = (rateId) => {
    const rate = userRateItem?.find((item) => item.rate_item.id === rateId);
    console.log(rate, 33333333);
    return rate ? rate.rate : 0;
  };
  const onChangeHanlde = (rateId, value) => {
    const item = rateItem?.find((item) => item.id === rateId);
    const userItem = userRateItem?.find((item) => item.rate_item.id === rateId);
    if (userItem) userItem.rate = value;
    else userRateItem.push({ rate: value, rate_item: item });
    forceUpdate(uuid());
  };
  return (
    <div className="modal-container">
      <Modal keyboard={true} open={open} onClose={() => setOpen(false)}>
        <Modal.Header>
          <Modal.Title>امتیاز شما</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {rateItem?.map((item, index) => {
            return (
              <div
                key={index}
                className="d-flex align-items-center justify-content-between"
              >
                <span>{item?.name}</span>

                <Rate
                  defaultValue={0}
                  color={rateColor[index]}
                  value={checkRate(item?.id)}
                  size="sm"
                  onChange={(value) => {
                    onChangeHanlde(item?.id, value);
                    // rateItem[index].rate = value;
                    // if (userRateItem[index]?.rate)
                    //   userRateItem[index].rate = value;
                    // forceUpdate(uuid());
                    // console.log(rateItem);
                  }}
                />
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={submitHandle} type="submit" className="btn-submit">
            <MdCheck color="white" size={24} />
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default DialogRate;
