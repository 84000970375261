import React, {
  forwardRef,
  useImperativeHandle,

  useState,
} from "react";
import { Modal } from "rsuite";
import Ruler from "rc-ruler";
import "rc-ruler/dist/index.css";
import { MdCheck } from "react-icons/md";

const DialogSetHip = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(160);
  useImperativeHandle(ref, () => ({
    open(hip) {
      setOpen(true);
      setValue(hip);
    },
  }));
 
  const handleDragChange = (value) => {
    setValue(value);
  };
  const handleSubmit = () => {
      
     props.onSelect({hip:value})
     setOpen(false)
  };
  return (
    <Modal  open={open} onClose={() => setOpen(false)}>
            <Modal.Header>درج دور باسن</Modal.Header>

      <Modal.Body style={{direction:"ltr",overflow: "inherit",
    padding: 10}}>
        <Ruler
          value={value}
          onDrag={handleDragChange}
          start={60}
          end={150}
          step={1}
        />
      </Modal.Body>
      <Modal.Footer>
      <p class="dialog-information">
              برای محاسبه دور باسن باید پهن‌ترین بخش باسن را پیدا کنید. <br/>
              البته در فرمی که باسن در حالت طبیعی خود باشد و منقبض نشده باشد. سپس متر را از روی آن به نحوی عبور دهید که کاملاً افقی و موازی با زمین باشد. در این حالت اندازه گیری دور باسن صحیح می‌باشد.<br/>
              
                                 </p>
        <button onClick={handleSubmit} className="btn-submit">
          <MdCheck color="white" size={24} />
        </button>
      </Modal.Footer>
    </Modal>
  );
});

export default DialogSetHip;
