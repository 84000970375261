import apiUser from "../../api/apiUser";

export const loadUserProfileAction = (jwt) => {
    return async (dispatch) => {
        console.log(jwt,"load profile")

        const user = await apiUser.loadUserProfile(jwt);
        console.log(user,444444)
        await dispatch({ type: "LOADUSER", payload: user })
    }
}