import React, { useState } from "react";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Carousel, IconButton } from "rsuite";

const TutorialFood = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const nextSlide = () => {
    if (activeIndex + 1 < props.food.baking_access.length)
      setActiveIndex(activeIndex + 1);
  };
  const backSlide = () => {
    if (activeIndex > 0) setActiveIndex(activeIndex - 1);
  };
  return (
    <div className="position-relative pt-3 pb-3" style={{ borderRadius: 8 }}>
      <p> آموزش مرحله به مرحله</p>

      <Carousel
        style={{
          borderRadius: 8,
          height: "auto",
          backgroundColor: "rgba(245, 248, 254, 1)",
        }}
        activeIndex={activeIndex}
        className="custom-slider"
      >
        {props.food?.baking_access?.map((step, index) => {
          return (
            <div
              key={index}
              className="p-2"
              style={{
                borderRadius: 8,
                backgroundColor: "rgba(245, 248, 254, 1)",
              }}
            >
              <div className="pb-3 d-flex  justify-content-between align-items-center w-100 ">
              <IconButton
                  onClick={backSlide}
                  placement="right"  appearance="link"
                  size="md"
                  
                  circle
                  icon={<MdArrowForwardIos size={16} />}
                >
                  مرحله قبل
                </IconButton>

                <span>مرحله {index + 1}</span>
                <IconButton
                  onClick={nextSlide}
                  size="sm"
                  style={{direction:"ltr"}}
                  circle appearance="link"
                  icon={<MdArrowBackIos  size={16} />}
                >
                  مرحله بعد
                </IconButton>
                
              </div>
              <p>{step.text}</p>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default TutorialFood;
