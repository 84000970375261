import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
} from "react";
import { Loader, Modal } from "rsuite";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { MdArrowForwardIos } from "react-icons/md";
import DialogUsername from "./DialogUsername";
import Timer from "../../common/Timer";
import apiUser from "../../../api/apiUser";
import { useDispatch, useSelector } from "react-redux";
import UserContext from "../../../context/UserContext";
import { saveJWTAction } from "../../../redux/actions/jwtActions";

const DialogOTP = forwardRef((props, ref) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false)
  const dispatch = useDispatch();
  const context = useContext(UserContext);
  const [, forceUpdate] = React.useState();
  const [error, setError] =React.useState("")
  const [code, setCode] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [response, setResponse] = React.useState("");
  const dialogUsername = useRef();
  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        required: "پر کردن این فیلد الزامی میباشد",
        size: "باید 6 عدد  باشد",
        code: " کد نوشته شده صحیح نمی باشد",
      },
      element: (message) => <div style={{ color: "red" }}>{message}</div>,
    })
  );
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (validator.current.allValid()) {
        loginDone();
      } else {
        validator.current.showMessages();
        forceUpdate(1);
      }
    } catch (ex) {
      toast.error("مشکلی پیش آمده.", {
        position: "top-right",
        closeOnClick: true,
      });
      console.log(ex);
    }
  };
  const requestOTP = async () => {
   setIsLoading(true);
    apiUser
      .requestOTP("+"+mobile, "")
      .then(({ response, error, server }) => {
        if (response) setResponse(response);
      })
      .finally(() =>setIsLoading(false));
  };
  const loginDone = () => {
    setIsLoading(true)
    apiUser
      .otpLogin("+"+mobile, code)
      .then(({ data, error }) => {
        // console.log( data, 77777777777,response.set_username );
        if (data) {
          dispatch(saveJWTAction(data));
          setIsLoading(false)
          if (response.set_username){

           props.onSuccess();
           close()

          }
          else dialogUsername.current.open();
        } else if (error) {
          
         setError(error.data.detail);
        }
      }).catch(e=>close())
      .finally(()=>{
        setIsLoading(false)
      })
  };
  const close = () =>{
    setError("")
    setShowDialog(false);}
  useImperativeHandle(ref, () => ({
    open(mobile, responseServer) {
      setMobile(mobile);
      setResponse(responseServer);
      setShowDialog(true);
    },
  }));

  return (
    <div className="modal-container">
      <DialogUsername ref={dialogUsername} onSuccess={()=> setShowDialog(false)}/>

      <Modal
        backdrop={"static"}
        keyboard={false}
        open={showDialog}
        onClose={close}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title>صفحه ورود</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group">
              <label className="fw-bold mb-2"> کد پیامک شده رو وارد کن</label>
              <label className="float-start">
                <Timer data={response} requestOTP={requestOTP} />
              </label>

              <input
                type="number"
                className="form-control input mt-2 mb-4"
                value={code}
                name="code"
                aria-describedby="code"
                placeholder="مثال:  123456"
                onChange={(e) => {

                  setCode(context.fixNumbers(e.target.value));
                  validator.current.showMessageFor("code");
                }}
              />
              {validator.current.message(
                "code",
                code,
                "required|size:6|integer"
              )}
              <div className="text-danger">{error}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn-submit mb-3">
              <MdArrowForwardIos color="white" size={24} />
            {isLoading &&  <Loader />}  

            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
});
export default DialogOTP;
